import { library } from '@fortawesome/fontawesome-svg-core';

// import your icons
import { faStar as faStarSolid} from "@fortawesome/free-solid-svg-icons";
import { faLayerGroup } from "@fortawesome/free-solid-svg-icons";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { faStar as faStarRegular} from "@fortawesome/free-regular-svg-icons";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { faBook } from "@fortawesome/free-solid-svg-icons";
import { faCompactDisc } from "@fortawesome/free-solid-svg-icons";
import { faVideo } from "@fortawesome/free-solid-svg-icons";
import { faCommentDots } from "@fortawesome/free-solid-svg-icons";

import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { faCube } from "@fortawesome/free-solid-svg-icons";
import { faToolbox } from "@fortawesome/free-solid-svg-icons";
import { faDatabase } from "@fortawesome/free-solid-svg-icons";
import { faHeart as faHeartSolid } from "@fortawesome/free-solid-svg-icons";
import { faHeart as faHeartRegular} from "@fortawesome/free-regular-svg-icons";
import { faQuestionCircle as faQuestionCircle} from "@fortawesome/free-solid-svg-icons";



library.add(
  faStarSolid,
  faStarRegular,
  faHome,
  faBook,
  faHeartSolid,
  faHeartRegular,
  faCommentDots,
  faLayerGroup,
  faUserCircle,
  faFilter,
  faDatabase,
  faCompactDisc,
  faQuestionCircle,
  faCube,
  faToolbox,
  faVideo

  
);