import React from "react";
import { timingSafeEqual } from "crypto";
import ReactQuill from "react-quill";
// import Project2 from './components/Project2';
import QuillComp from "./QuillComp";
import axios from "axios";
// import FileBase64 from "react-file-base64";
import { MentionsInput, Mention } from "react-mentions";


import { Redirect } from "react-router-dom";
// import { removeAllListeners } from '../../../api/app';

class ProjectForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasimage: false,
      image: "",
      subject: "nothing",
      color: "grey",
      projectHeaderStyle: {
        backgroundImage:
          "linear-gradient(to bottom, rgba(0,0,0,0.8), rgba(0,0,0,0.1)), url(.uploads/)",
        minHeight: "130px",
      },
      allusers: [],
      usersTempValue: "",
    };

    this.uploadImage = this.uploadImage.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.changecolor = this.changecolor.bind(this);
    this.getUsers = this.getUsers.bind(this);
  }

  getUsers() {
    axios
      .get((process.env.REACT_APP_API_IP || "http://localhost:9000") +"/getAllUsers")
      .then((res) => {
        if (res.data) {
          this.setState(
            {
              allusers: res.data,
            },
            function () {
              console.log("got all users:");
              console.log(this.state.allusers);
            }
          );
        }
      })
      .catch((err) => console.log(err));
  }

  handleChange(e) {
    this.props.handleChange(e);

    console.log("local handlechange");
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        console.log("callback");
        console.log(this.state.subject);
        this.changecolor(this.state.subject);
      }
    );
  }

  changecolor(subject) {
    console.log("changing color");
    switch (subject) {
      case "exploration medicine":
        this.setState({
          color: "#DC3D52",
        });
        this.state.color = "#DC3D52";
        break;
      case "space weather":
        this.setState({
          color: "#EFB93B",
        });

        break;
      case "lunar exploration":
        this.setState({
          color: "#41539E",
        });

        break;
      case "astrobiology":
        this.setState({
          color: "#30A0A3",
        });

        break;
      case "planetary defense":
        this.setState({
          color: "#941D81",
        });

        break;
      case "mission operations":
        this.setState({
          color: "#6A549D",
        });

        break;
      case "earth science":
        this.setState({
          color: "#30AC67",
        });

        break;
      case "disaster response":
        this.setState({
          color: "#F08B02",
        });

        break;
      case "planetary stewardship":
        this.setState({
          color: "#4A83C4",
        });

        break;
    }
  }
  
  uploadImage(e) {

    var fd = new FormData();
    fd.append("file", e.target.files[0], e.target.files[0].name);
    fd.append("filename", Date.now() + e.target.files[0].name);

    axios
      .post((process.env.REACT_APP_API_IP || "http://localhost:9000") +"/uploadImage", fd)
      .then((res) => {
        if (res) {
          let backgroundImage =
            'linear-gradient(to bottom, rgba(0,0,0,0.5), rgba(0,0,0,0.1)), url("' +
            res.data.imagedata +
            '")';

          this.setState({
            image: res.data,
            projectHeaderStyle: {
              backgroundImage: backgroundImage,
              minHeight: "150px",
            },
            hasimage: true,
          });

          const event = {
            target: {
              name: "coverimage",
              value: res.data._id,
            },
          };
          this.props.handleChange(event);
        }
      })
      .catch((err) => {
        alert("Error, could not upload image, please try again!");
      });
  }

  modules = {
    toolbar: [
      [{ header: [1, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
  };

  formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];

  componentDidMount() {
    this.getUsers();
  }

  researchersChange(event) {
    this.setState({
      usersTempValue: event.target.value,
    });
  }

  render() {
    var boxBarStyle = {
      backgroundColor: this.state.color,
    };
    return (
      <div>
        <div
          className="project-list-header"
          style={this.state.projectHeaderStyle}
        >
          {!this.state.hasimage ? (
            <div class="upload-header">
              <h2>Upload a header image for the project</h2>

              <input
                accept="image/*"
                type="file"
                name="myImage"
                enctype="multipart/form-data"
                onChange={(e) => this.uploadImage(e)}
              ></input>
            </div>
          ) : (
            <p></p>
          )}
        </div>
        <div className="project-title-box-bar" style={boxBarStyle}></div>

        <div className="project-holder max-width">
          <form onSubmit={this.handleFormSubmit}>
            <input
              id="project-title-input"
              type="text"
              name="title"
              placeholder="Title"
              value={this.props.title}
              onChange={this.props.handleChange}
            ></input>

            <input
              id="project-subtitle-input"
              type="text"
              name="subtitle"
              placeholder="Subtitle"
              value={this.props.subtitle}
              onChange={this.props.handleChange}
            ></input>

            <br />
            <div className="pure-g project-summaries">
              <div className="pure-u-1-2 team-box">
                <label>researchers (Separated by a comma)</label>
                <textarea
                  type="text"
                  name="researchers"
                  placeholder="Researchers"
                  value={this.props.researchers}
                  onChange={this.props.handleChange}
                  rows="3"
                ></textarea>

                <label>Faculty</label>
                <textarea
                  type="text"
                  name="mentors"
                  placeholder="faculty"
                  value={this.props.mentors}
                  onChange={this.props.handleChange}
                  rows="3"
                ></textarea>
              </div>
              <div className="pure-u-1-2 details-box">
              <label>Programme</label>
                <select
                  name="programme"
                  value={this.props.programme}
                  onChange={this.props.handleChange}
                >
                  <option value="null">Pick a programme</option>
                  <option value="FDL US">NASA FDL</option>
                  <option value="FDL Europe">FDL Europe</option>
                  {/* <option value="DataQuest">FDL Europe</option> */}
                </select>
                <textarea
                      type="text"
                      name="institution"
                      placeholder="institution"
                      value={this.props.institution}
                      onChange={this.props.handleChange}
                      rows="1"
                    ></textarea>
                <label>Year</label>
                <select
                  name="year"
                  value={this.props.year}
                  onChange={this.props.handleChange}
                >
                  <option value="2020">2020</option>
                  <option value="2019">2019</option>
                  <option value="2018">2018</option>
                  <option value="2017">2017</option>
                  <option value="2016">2016</option>
                </select>

                
               
                <label>Subject</label>
                <select
                  name="subject"
                  value={this.props.subject}
                  onChange={this.handleChange}
                >
                  <option value="null">Pick a subject</option>
                  <option value="exploration medicine">
                    exploration medicine
                  </option>
                  <option value="space weather">space weather</option>
                  <option value="lunar exploration">lunar exploration</option>
                  <option value="astrobiology">astrobiology</option>
                  <option value="planetary defense">planetary defense</option>
                  <option value="mission operations">mission operations</option>
                  <option value="earth science">earth science</option>
                  <option value="disaster response">disaster response</option>
                  <option value="planetary stewardship">
                    planetary stewardship
                  </option>
                </select>
              </div>
            </div>

            <br />

            {/* <MentionsInput
              singleLine="true"
              value={this.state.usersTempValue}
              onChange={this.researchersChange.bind(this)}
              placeholder={"Mention people using '@'"}
            >
              <Mention
                data={this.state.allusers.map(user=>({display:user.display, id:user._id}))}
              />
            
            </MentionsInput> */}
            <br />

            <label>overview</label>
            <ReactQuill
              theme="snow"
              name="summary"
              modules={this.modules}
              formats={this.formats}
              defaultValue={this.props.summary}
              className="overview-content"
              onChange={(html) =>
                this.props.handleChange({
                  target: { value: html, name: "summary" },
                })
              }
            />

            <br />
            <br />
            <br />
            <button
              className="button-plain button-plain-black"
              onClick={this.props.handleFormSubmit}
            >
              submit
            </button>
          </form>
        </div>
      </div>
    );
  }
}

export default ProjectForm;
