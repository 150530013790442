import React from "react";
import axios from "axios";


import ProjectCard from "../components/ProjectCard";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
} from "react-router-dom";
import { Gradient } from 'react-gradient';
import Project from "./Project";
import Dataset from "./Dataset";
import ProjectEditor from "./ProjectEditor";
import Spinner from "react-spinkit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


class HelioBrowser extends React.Component {
    constructor(props){
        super(props);
        this.state={

        }
    }

    componentDidMount(){

    }

    render() {

        return(
            <div className="project-explorer">
                <Switch>
                    <Route path="/repo/project/:projectId/:showdataset?/:username?" component={Project} />

                    <Route path="/">
                        <div className="project-list-header helio-background">
                            <div className="project-list-nav-holder">
                                <div className="project-list-nav max-width pure-g">
                                    <div className="pure-u-4-5 project-list-intro">
                                       
                                        <h1>
                                        Explore HELIO<br /> AI Research
                                        </h1>
                                        <h2>Analysis ready data and ML-OPS from NASA Heliophysics</h2>
                                        <br/>
                                        <br/>
                                        <a href="https://science.nasa.gov/heliophysics" className="helio-nasa-link">
                                            Heliophysics at NASA
                                        </a>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                        <div className="pure-g max-width project-list"> 
                        {this.props.projects ? (
                            this.props.projects.map((project) =>{
                                return(
                                    <Link 
                                    className="pure-u-xl-1-4 pure-u-lg-1-3 pure-u-md-1-2 pure-u-sm-1-1 pure-u-1-1 card-grid-box"  
                                    to={{ pathname: `/repo/project/${project._id}/false/${this.props.username}` , 
                                    state: {  
                                      user:this.state.user,
                                      isauth:this.props.isauth
                                    },}}
                                    >
                                        <ProjectCard project={project} />
                                    </Link>
                                    
                                );
                            })
                        ):(
                            <div></div>
                        )
                        }

{this.props.datasets ?(
                this.props.datasets.map((dataset) => {
                  return(
                   
                     
                    <Link to={{ pathname: `/repo/project/${dataset.project_id}/true` , state: {  
                      user:this.state.user,
                      isauth:this.props.isauth
                    },}}  className="pure-u-xl-1-4 pure-u-lg-1-3 pure-u-md-1-2 pure-u-sm-1-1 pure-u-1-1 card-grid-box">
                  <div className="cut-card-wrap">
             
                <Gradient gradients = {[
                  [dataset.color, 'white'],
                  [dataset.color, dataset.color]
                ]}
                property="background"
                angle="33deg"
		            transitionType="sequential"
                duration="2000"
                className="cut-card"
                >
                  &nbsp;
                    <div className="cat-tag"> 
                    <FontAwesomeIcon icon={["fas", "database"] } size="s"/>&nbsp; dataset
                    </div>
                  <div className="dataset-card-info">
                    <h1>{dataset.title}</h1>
                    <h2>{dataset.subtitle}</h2>
                    {/* {dataset._id} */}
                    {/* <button onClick={() => {this.removeDataset(dataset._id)}}>rm</button> */}
                    <span class="card-stats">
                      <FontAwesomeIcon
                        className="commentcolor"
                        icon={["fas", "compact-disc"]}
                      />{" "}
                      {dataset.size} &nbsp;
                      
                    </span>
                  
                </div>
                </Gradient>
                
              </div>
              
              </Link>
           
                  );
                })
              ):(
                <div className="project-list-spinner">
                <Spinner fadeIn="none" name="three-bounce" />
              </div>
              )}
                        </div>
                    </Route>
                </Switch>

            </div>
        )
    }

}

export default HelioBrowser;