import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import axios from "axios";
import { Gradient } from 'react-gradient';
import Modal from "react-modal";
import { send } from 'emailjs-com';

import Spinner from "react-spinkit";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
} from "react-router-dom";

class Dataset extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      toSend:[],
      name:"",
      organisation:"",
      role:"",
      email:"",
      telephone:"",
      supervisor:"",
      supervisoremail:"",
      projectend:"",
      projectstart:"",
      information:"",
      showRequestModal: false
    };

    this.removeDataset = this.removeDataset.bind(this);
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.onRequestClose = this.onRequestClose.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  removeDataset(){
    axios
      .post((process.env.REACT_APP_API_IP || "http://localhost:9000") +"/removeDataset", {
        id: this.props.dataset._id,
      })
      .then((res) => {
        if (res.data) {
          console.log("removed");
          // window.location.href = "http://spaceml.org";
        }
      })
      .catch((err) => console.log(err));
  }

  handleOpenModal() {
    this.setState({ showRequestModal: true });
  }
  handleCloseModal() {
    this.setState({ showRequestModal: false });
  }

  onRequestClose() {
    this.setState({ showRequestModal: false });
  }

  onSubmit(e){
    e.preventDefault();


    var collected_data = {
      name:this.state.name,
      organisation:this.state.organisation,
      role:this.state.role,
      email:this.state.email,
      telephone:this.state.telephone,
      supervisor:this.state.supervisor,
      supervisoremail:this.state.supervisoremail,
      projectend:this.state.projectend,
      projectstart:this.state.projectstart,
      information:this.state.information,
      dataset:this.props.dataset[0].title
    }

    const webhookurl = 'https://hooks.slack.com/services/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'

    const data = {
      "text": "NAME: "+collected_data.name +"\n"+
      "ORG: "+collected_data.organisation +"\n"+
      "ROLE: "+collected_data.role +"\n"+
      "EMAIL: "+collected_data.email +"\n"+
      "TELE: "+collected_data.telephone +"\n"+
      "SUPER: "+collected_data.supervisor +"\n"+
      "SUPER EMAIL: "+collected_data.supervisoremail +"\n"+
      "START: "+collected_data.projectend +"\n"+
      "END: "+collected_data.projectstart +"\n"+
      "INFO: "+collected_data.information +"\n"+
      "DATASET: "+collected_data.dataset +"\n"
    }
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded'
    }

    axios
      .post(webhookurl, JSON.stringify(data), {
        withCredentials: false,
        transformRequest: [(data, headers) => {
          delete headers.common["Authorization"]
          return data
        }]
      })
      .then((res) => {
        if (res.status === 200) {
          this.setState({ showRequestModal: false });
        } else {
          alert("There was an error.  Please try again later.")
          this.setState({ showRequestModal: false });
        }
      })
      .catch((err) => console.log(err));
    

    // send(
    //   'service_52lfdal',
    //   'template_e7liz2u',
    //   data,
    //   'user_qsXULY1Ap1o0WboitR2jz'
    // )
    //   .then((response) => {
    //     console.log('SUCCESS!', response.status, response.text);
    //     this.setState({ showRequestModal: false });
    //   })
    //   .catch((err) => {
    //     console.log('FAILED...', err);
    //     this.setState({ showRequestModal: false });
    //   });
  }

  handleChange(e){
    this.setState({[e.target.name]: e.target.value});
  }
  componentDidMount() {

  }

  render() {
    var datasetHeaderStyle = {
      backgroundColor: "black",
      backgroundImage:
        'linear-gradient(to bottom, rgba(0,0,0,0.8), rgba(0,0,0,0.1)), url("")',
      minHeight: "10px",
    };
  

    return (
      <div>
       <Modal
        closeTimeoutMS={500}
        isOpen={this.state.showRequestModal}
        onRequestClose={this.handleCloseModal}
        shouldCloseOnEsc={true}
        overlayClassName={"userform-overlay"}
        className="user-form-content"
      >
       
        <h1>Request Full Dataset Access</h1>
        <form onSubmit={this.onSubmit}  className="datasetform">
          <p>Please fill out the following form if you wish to use the datasets associated with the Heliophysics Projects listed below</p>
            <input
              type='text'
              id="name"
              name='name'
              placeholder='Full Name'
              value={this.state.name}
              onChange={this.handleChange}
            />
            <input
              type='text'
              id="organisation"
              name='organisation'
              placeholder='Organizaton'
              value={this.state.organisation}
              onChange={this.handleChange}
            />
            <input
              type='text'
              id="role"
              name='role'
              placeholder='Position / Role'
              value={this.state.role}
              onChange={this.handleChange}
            />
            <input
              type='text'
              id="email"
              name='email'
              placeholder='Email'
              value={this.state.email}
              onChange={this.handleChange}
            />
            <input
              type='text'
              id="telephone"
              name='telephone'
              placeholder='Telephone Number'
              value={this.state.telephone}
              onChange={this.handleChange}
            />
            <input
              type='text'
              id="supervisor"
              name='supervisor'
              placeholder='Department Head / Lead'
              value={this.state.supervisor}
              onChange={this.handleChange}
            />
            <input
              type='text'
              id="supervisoremail"
              name='supervisoremail'
              placeholder='Department Head / Lead Email'
              value={this.state.supervisoremail}
              onChange={this.handleChange}
            />
            <input
              type='text'
              id="projectstart"
              name='projectstart'
              placeholder='Project Start Date'
              value={this.state.projectstart}
              onChange={this.handleChange}
            />
            <input
              type='text'
              id="projectend"
              name='projectend'
              placeholder='Project End Date'
              value={this.state.projectend}
              onChange={this.handleChange}
            />
            {/* <p></p> */}
            <textarea
              type='text'
              name='information'
              placeholder='In addition to the information provided above, please provide a description of your research plans and the intended use of the data. Include your proposed hypothesis(es) and why the SPACEML data set(s) are suitable for addressing your research.'
              value={this.state.information}
              onChange={this.handleChange}
              rows="3s"
            />
            
              <button type='submit'>Submit Request</button>
          </form>
       
      </Modal>
      {this.props.dataset.length > 0 ?(
        this.props.dataset.map((dataset) => {
          return (
            <div className="dataset-wrapper">
        <div className="dataset-container ">
        <Gradient gradients = {[
                  [dataset.color, 'white'],
                  [dataset.color, dataset.color]
                ]}
                property="background"
                angle="33deg"
		            transitionType="sequential"
                duration="2000"
                className="dataset-header"
                > </Gradient>
          {/* <div className="dataset-header" style={datasetHeaderStyle}></div> */}
          <div className="dataset-holder pure-g">
            <div className="pure-u-1-2 project-summaries">
           
            <h1>{dataset.title}</h1>
              <h2>
              {dataset.subtitle}
              </h2>
              {dataset.colablink ?(
                <a href={dataset.colablink}  className="colab-button">
                &nbsp;Colab Notebook
              </a>
              ):(null)}
              
              {this.props.subject == "space weather"?(
                 <a onClick={this.handleOpenModal} className="colab-button">
                 &nbsp;Request Access
               </a>
              ):(
                <div style={{display:'inline-block'}}>

                {dataset.datasetlink && dataset.datasetlink.length > 1 ? (
                
                  <div> 
              
                  
                    
                    {this.props.isauth?(
                      <a href={dataset.datasetlink} className="colab-button">
                      &nbsp;{dataset.datasetlinktitle}
                    </a>
                    ):(<a  className="colab-button" onClick={this.props.openRegister}>
                    &nbsp;Please register / log in to access dataset
                  </a>)}
                    
                  </div>
                  
                )
                
                
                 :
                 null}
                 </div>

              )}

            
                <div style={{display:'inline-block'}}>
                   
                {dataset.datasetlink2 && dataset.datasetlink2.length > 1 ? (
                
                  <div> 
              
                  
                    
                    {this.props.isauth?(
                      <a href={dataset.datasetlink2} className="colab-button">
                      &nbsp;{dataset.datasetlinktitle2}
                    </a>
                    ):(<a  className="colab-button" onClick={this.props.openRegister}>
                    &nbsp;Please register / log in to access dataset
                  </a>)}
                    
                  </div>
                  
                )
                
                
                 :
                 null}
                 </div>

             
             
              
            </div>

            <div className="pure-u-1-2 project-summaries">
              <span>dataset info</span>
              {/* <h3>Challenge:</h3>
              <h4>{this.props.dataset[0].challenge}</h4> */}
              {dataset.size && dataset.size.length > 1 ?
              <div>
                <h3>Size:</h3>
                <h4>{dataset.size}</h4>
              </div> :
              null
              }

              {dataset.type && dataset.type.length > 1 ?
              <div>
                <h3>Type:</h3>
                <h4>{dataset.type}</h4>
              </div> :
              null
              }   
              {dataset.dataformat && dataset.dataformat.length > 1 ?
              <div>
                <h3>Format:</h3>
                <h4>{dataset.dataformat}</h4>
              </div> :
              null
              }  
              {dataset.dataowner && dataset.dataowner.length > 1 ?
              <div>
                <h3>Owner:</h3>
                <h4>{dataset.dataowner}</h4>
              </div> :
              null
              }  
              {dataset.acknowledgements && dataset.acknowledgements.length > 1 ?
              <div>
                <h3>Acknowledgements:</h3>
                <h4>{dataset.acknowledgements}</h4>
              </div> :
              null
              }  

              

            </div>
            <div className="pure-g overview-content medium-width ">
              <div className="pure-u-1-1 ">
                <div className="overview-text">
                  <h1>Context</h1>
                  <p>
                  {dataset.context}
                  </p>
                </div>
              </div>

              <div className="pure-u-1-1 ">
                <div className="overview-text">
                  <h1>Intended use</h1>
                  <p>
                  {dataset.intendeduse}
                  </p>
                </div>
              </div>

              <div className="pure-u-1-1 ">
                <div className="overview-text">
                  <h1>Ethical considerations</h1>
                  <p>
                  {dataset.ethicalconsiderations}
                  </p>
                </div>
              </div>
{/* 
              <div className="pure-u-1-1 ">
                <div className="overview-text">
                  <h1>Papers & Publications</h1>
                  <p>
                  <a href={dataset.publications}>{dataset.publications}</a>
                  </p>
                </div>
              </div> */}
              


            </div>
          </div>
        </div>
      </div>
          );
        })
        

      ):(
        <p>No datasets</p>
      )
      
    }
    </div>
    );
  }
}

export default Dataset;
