import React from "react";
import { timingSafeEqual } from "crypto";
import ReactQuill, {Quill} from "react-quill";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
// import Project2 from './components/Project2';
import QuillComp from "./QuillComp";
import axios from "axios";
// import FileBase64 from "react-file-base64";
import { MentionsInput, Mention } from "react-mentions";

import Axios from "axios";
import { Redirect } from "react-router-dom";
// import { removeAllListeners } from '../../../api/app';





class ProjectEditor extends React.Component {
  constructor(props) {
    super(props);
    this.quillRef = null; // Quill instance
    // this.quillRef2 = "hey"; // Quill instance 2
    this.state = {
      dataset:{
        project_id:""
      },
      datasets:[],
      project: "",
      hasimage: false,
      image: "",
      quillContent: "<h1>hey</h1>",
      subject: "nothing",
      color: "grey",
      quillRef2: null,
      projectHeaderStyle: {
        backgroundImage:
          "linear-gradient(to bottom, rgba(0,0,0,0.8), rgba(0,0,0,0.1)), url(.uploads/)",
        minHeight: "130px",
      },
      allusers: [],
      usersTempValue: "",
    };
    this.datasetQuillref= "";

    this.uploadImage = this.uploadImage.bind(this);
    this.handleChange = this.handleChange.bind(this);
    // this.getProject = this.getProject.bind(this);
    this.handleQuillChange = this.handleQuillChange.bind(this);
    this.updateProject = this.updateProject.bind(this);
    this.handleDatasetChange = this.handleDatasetChange.bind(this);
    this.saveDataset = this.saveDataset.bind(this);
    this.updateDataset = this.updateDataset.bind(this);
    this.uploadImageToGcs = this.uploadImageToGcs.bind(this);
    this.getUsers = this.getUsers.bind(this);
  }



  updateProject(event) {
    if(event){
      event.preventDefault();
    }
    
    if(this.state.project.colablink !== ""){
      console.log("there is a colab link!");
      var dummyproject = this.state.project
      dummyproject.hascolablink = true;
      this.setState({
        project:dummyproject
      })
    }

    axios
      .post((process.env.REACT_APP_API_IP || "http://localhost:9000") +"/updateProject", this.state.project)
      .then((res) => {
        if (res.data) {
          console.log("updated project");
          window.location.href = "http://spaceml.org";
        }
      })
      .catch((err) => console.log(err));
  }

  saveDataset(event) {
    event.preventDefault();

    axios
      .post((process.env.REACT_APP_API_IP || "http://localhost:9000") +"/postDataset", this.state.dataset)
      .then((res) => {
        if (res.data){
          console.log("posting dataset");
          console.log(res.data.dataset._id);

          var dummyproject = { ...this.state.project };
          dummyproject.datasets[0] = res.data.dataset._id;
          

          this.setState(
            {
              project: dummyproject,
            },
            () => {
              this.updateProject()
              console.log(this.state.project);
            }
          );
          
        }
      })
      .catch((err) => console.log(err));
  }

  updateDataset(event) {
    event.preventDefault();
    console.log(this.state.datasets[0])
    axios
      .post((process.env.REACT_APP_API_IP || "http://localhost:9000") +"/updateDataset", this.state.datasets[0])
      .then((res) => {
        if (res.data){
          console.log("posting dataset");
          console.log(res.data.dataset._id);

          var dummyproject = { ...this.state.project };
          dummyproject.datasets[0] = res.data.dataset._id;

          this.setState(
            {
              project: dummyproject,
            },
            () => {
              this.updateProject()
              console.log(this.state.project);
            }
          );
          
        }
      })
      .catch((err) => console.log(err));
  }

  getUsers() {
    axios
      .get((process.env.REACT_APP_API_IP || "http://localhost:9000") +"/getAllUsers")
      .then((res) => {
        if (res.data) {
          this.setState(
            {
              allusers: res.data,
            },
            function () {
              console.log("got all users:");
              console.log(this.state.allusers);
            }
          );
        }
      })
      .catch((err) => console.log(err));
  }

  handleChange(e) {
    console.log("local handlechange");
    var dummyproject = { ...this.state.project };
    dummyproject[e.target.name] = e.target.value;


    this.setState(
      {
        project: dummyproject,
      },
      () => {
        console.log(this.state.project);
      }
    );
  }

  handleDatasetChange(e){
    if(this.state.datasets.length > 0){
      var dummydataset = { ...this.state.datasets[0]};
      dummydataset.subject = this.props.location.state.project.subject;
      dummydataset[e.target.name] = e.target.value;
      this.setState(
        {
          datasets:[dummydataset],
        },
        () => {
          console.log(this.state.datasets[0]);
        }
      )
    } else {
      console.log("local dataset change");
    var dummydataset = { ...this.state.dataset};
    dummydataset[e.target.name] = e.target.value;

    this.setState(
      {
        dataset:dummydataset,
      },
      () => {
        console.log(this.state.dataset);
      }
    )
    }
    
  }

  handleQuillChange() {
    console.log("quill change!");
    if(this.quillRef){
      var dummyproject = { ...this.state.project };
      dummyproject.summary = this.quillRef.getEditorContents();
      this.setState({ project: dummyproject });
      console.log(this.state.project);
    }
    
  }

  handleQuillChangeUpdateDataset() {
    console.log("quill change update dataset!");
    console.log(this.datasetQuillref);
    // if(this.datasetQuillref){
    //   console.log("got quillref 2");
    //   var dummydataset = { ...this.state.datasets[0]};
    //   dummydataset.summary = this.datasetQuillref.getEditorContents();
 
    //   this.setState({ datasets: [dummydataset] });
    //   console.log(this.state.project);
    // }
    
  }

  uploadImageToGcs(e) {

    var fd = new FormData();
    fd.append("file", e.target.files[0], e.target.files[0].name);
    fd.append("filename", Date.now() + e.target.files[0].name);

    axios
      .post((process.env.REACT_APP_API_IP || "http://localhost:9000") +"/uploadgcsimage", fd)
      .then((res) => {
        if (res) {
          console.log("saved image returned with:");
          console.log(res)
          let backgroundImage =
            'linear-gradient(to bottom, rgba(0,0,0,0.5), rgba(0,0,0,0.1)), url("' +
            res.data.imagedata +
            '")';

            this.setState({
              image: res.data,
              projectHeaderStyle: {
                backgroundImage: backgroundImage,
                minHeight: "150px",
              },
              hasimage: true,
            });

            const event = {
              target: {
                name: "coverimage",
                value: res.data._id,
              },
            };
            this.handleChange(event);

        }
      })
      .catch((err) => {
        alert("Error, could not upload image, please try again!");
      });
  }

  uploadImage(e) {
    var fd = new FormData();
    fd.append("file", e.target.files[0], e.target.files[0].name);
    fd.append("filename", Date.now() + e.target.files[0].name);

    axios
      .post((process.env.REACT_APP_API_IP || "http://localhost:9000") +"/uploadImage", fd)
      .then((res) => {
        if (res) {
          let backgroundImage =
            'linear-gradient(to bottom, rgba(0,0,0,0.5), rgba(0,0,0,0.1)), url("' +
            res.data.imagedata +
            '")';

          this.setState({
            image: res.data,
            projectHeaderStyle: {
              backgroundImage: backgroundImage,
              minHeight: "150px",
            },
            hasimage: true,
          });

          const event = {
            target: {
              name: "coverimage",
              value: res.data._id,
            },
          };
          this.props.handleChange(event);
        }
      })
      .catch((err) => {
        alert("Error, could not upload image, please try again!");
      });
  }

  modules = {
    toolbar: [
      [{ header: [1, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
      ["video"],
    ],
  };

  formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "clean"
,    "video",
    
  ];

  componentDidMount() {
    //   this.getProject();
    console.log("!!!!!!");
    console.log(this.props.location.state);
    var dummydataset = { ...this.state.dataset};
    dummydataset.project_id = this.props.location.state.project._id;
    dummydataset.color = this.props.location.state.project.color;
    dummydataset.subject = this.props.location.state.project.subject;
    
    if(this.props.location.state.datasets[0]){
      this.setState({
        project: this.props.location.state.project,
        quillContent: this.props.location.state.project.summary,
        quillContent2:this.props.location.state.datasets[0].summary,
        dataset:dummydataset,
        datasets:this.props.location.state.datasets
      });
    } else {
      this.setState({
        project: this.props.location.state.project,
        quillContent: this.props.location.state.project.summary,
        dataset:dummydataset,
        datasets:this.props.location.state.datasets
      });
    }

    // this.getUsers();
  }

  researchersChange(event) {
    this.setState({
      usersTempValue: event.target.value,
    });
  }

  render() {
    var boxBarStyle = {
      backgroundColor: this.state.color,
    };
    return (
      <div>
        {this.state.project == "" ? (
          <p>loading</p>
        ) : (
          <div>
            <div
              className="project-list-header"
              style={this.state.projectHeaderStyle}
            >
              {!this.state.hasimage ? (
                <div class="upload-header">
                 
                  <h2>Upload a header image for the project</h2>

                  

                  <input
                    accept="image/*"
                    type="file"
                    name="myImage"
                    enctype="multipart/form-data"
                    onChange={(e) => this.uploadImageToGcs(e)}
                  ></input>
                </div>
              ) : (
                <p></p>
              )}
            </div>
            <div className="project-title-box-bar" style={boxBarStyle}></div>
            <div className="project-holder max-width">
            
              <form onSubmit={this.updateProject}>
                <input
                  id="project-title-input"
                  type="text"
                  name="title"
                  placeholder="Title"
                  value={this.state.project.title}
                  onChange={this.handleChange}
                ></input>

                <input
                  id="project-subtitle-input"
                  type="text"
                  name="subtitle"
                  placeholder="Subtitle"
                  value={this.state.project.subtitle}
                  onChange={this.handleChange}
                ></input>

                <br />
                <div className="pure-g project-summaries">
                  <div className="pure-u-1-2 team-box">
                    <label>researchers (Separated by a comma)</label>
                    <textarea
                      type="text"
                      name="researchers"
                      placeholder="Researchers"
                      value={this.state.project.researchers}
                      onChange={this.handleChange}
                      rows="3"
                    ></textarea>

                    <label>Faculty</label>
                    <textarea
                      type="text"
                      name="mentors"
                      placeholder="faculty"
                      value={this.state.project.mentors}
                      onChange={this.handleChange}
                      rows="3"
                    ></textarea>
                  </div>
                  <div className="pure-u-1-2 details-box">
                    <label>Programme</label>
                    <select
                      name="programme"
                      value={this.state.project.programme}
                      onChange={this.handleChange}
                    >
                      <option value="null">Pick a programme</option>
                      <option value="FDL US">NASA FDL</option>
                      <option value="FDL Europe">FDL Europe</option>
                      {/* <option value="DataQuest">FDL Europe</option> */}
                    </select>
                    <label>Insitution</label>
                    <textarea
                      type="text"
                      name="institution"
                      placeholder="institution"
                      value={this.state.project.institution}
                      onChange={this.handleChange}
                      rows="1"
                    ></textarea>
                    
                    <label>Year</label>
                    <select
                      name="year"
                      value={this.state.project.year}
                      onChange={this.handleChange}
                    >
                      <option value="2022">2022</option>
                      <option value="2021">2021</option>
                      <option value="2020">2020</option>
                      <option value="2019">2019</option>
                      <option value="2018">2018</option>
                      <option value="2017">2017</option>
                      <option value="2016">2016</option>
                    </select>

                    <label>demo colab notebook</label>
                    <textarea
                      type="text"
                      name="colablink"
                      placeholder="Colab notebook link"
                      value={this.state.project.colablink}
                      onChange={this.handleChange}
                      rows="1"
                    ></textarea>

                    <label>gitlab / github link</label>
                    <textarea
                      type="text"
                      name="gitlink"
                      placeholder="Git repo link"
                      value={this.state.project.gitlink}
                      onChange={this.handleChange}
                      rows="1"
                    ></textarea>

                    <label>external website link</label>
                    <textarea
                      type="text"
                      name="weblink"
                      placeholder="Web link"
                      value={this.state.project.weblink}
                      onChange={this.handleChange}
                      rows="1"
                    ></textarea>

             
                  </div>
                </div>

                <br />

                {/* <MentionsInput
            singleLine="true"
            value={this.state.usersTempValue}
            onChange={this.researchersChange.bind(this)}
            placeholder={"Mention people using '@'"}
          >
            <Mention
              data={this.state.allusers.map(user=>({display:user.display, id:user._id}))}
            />
          
          </MentionsInput> */}
                <Tabs>
                  <TabList>
                    <Tab>Overview</Tab>
                    <Tab>Dataset</Tab>
                    <Tab>Connect Dataset</Tab>
                  </TabList>

                  <TabPanel>
                    <ReactQuill
                      ref={(element) => {
                        this.quillRef = element
                      
                      }}
                      theme="snow"
                      name="summary"
                      modules={this.modules}
                      formats={this.formats}
                      value={this.state.project.summary}
                      className="overview-content"
                      onChange={this.handleQuillChange}
                    ></ReactQuill>
                  </TabPanel>

                  <TabPanel>
                    {/* <h1>new dataset</h1> */}
                    {this.state.datasets[0] ? (
                      // <p>{this.state.datasets.length}</p>
                      <div>
                      <form onSubmit={this.datasetsave}>
                          <input
                        id="project-title-input"
                        type="text"
                        name="title"
                        placeholder="dataset title"
                        value={this.state.datasets[0].title}
                        onChange={this.handleDatasetChange}
                      ></input>
                      <input
                        id="project-subtitle-input"
                        type="text"
                        name="subtitle"
                        placeholder="dataset subtitle"
                        value={this.state.datasets[0].subtitle}
                        onChange={this.handleDatasetChange}
                      ></input>
                       <div className="pure-g dataset-box">
                        <div className="pure-u-1-2 details-box">
                          <label>dataset size</label>
                          <textarea
                            type="text"
                            name="size"
                            placeholder="dataset size"
                            value={this.state.datasets[0].size}
                            onChange={this.handleDatasetChange}
                            rows="1"
                          ></textarea>

                          <label>data type</label>
                          <textarea
                            type="text"
                            name="datatype"
                            placeholder="data type"
                            value={this.state.datasets[0].datatype}
                            onChange={this.handleDatasetChange}
                            rows="1"
                          ></textarea>
                          <label>data format</label>
                          <textarea
                            type="text"
                            name="dataformat"
                            placeholder="data format"
                            value={this.state.datasets[0].dataformat}
                            onChange={this.handleDatasetChange}
                            rows="1"
                          ></textarea>
                        </div>
                        <div className="pure-u-1-2 details-box">
                          <label>data owners</label>
                          <textarea
                            type="text"
                            name="dataowner"
                            placeholder="data owners"
                            value={this.state.datasets[0].dataowner}
                            onChange={this.handleDatasetChange}
                            rows="1"
                          ></textarea>

                          <label>acknowledgements</label>
                          <textarea
                            type="text"
                            name="acknowledgements"
                            placeholder="acknowledgements"
                            value={this.state.datasets[0].acknowledgements}
                            onChange={this.handleDatasetChange}
                            rows="1"
                          ></textarea>

                          <label>demo colab notebook</label>
                          <textarea
                            type="text"
                            name="colablink"
                            placeholder="colab notebook"
                            value={this.state.datasets[0].colablink}
                            onChange={this.handleDatasetChange}
                            rows="1"
                          ></textarea>
                          <label>Link to public dataset</label>
                          <textarea
                            type="text"
                            name="datasetlink"
                            placeholder="Dataset Link"
                            value={this.state.datasets[0].datasetlink}
                            onChange={this.handleDatasetChange}
                            rows="1"
                          ></textarea>
                          <label>Link title</label>
                          <textarea
                            type="text"
                            name="datasetlinktitle"
                            placeholder="Dataset Link"
                            value={this.state.datasets[0].datasetlinktitle}
                            onChange={this.handleDatasetChange}
                            rows="1"
                          ></textarea>
                          <label>Link to public dataset 2</label>
                          <textarea
                            type="text"
                            name="datasetlink2"
                            placeholder="Dataset Link Title"
                            value={this.state.datasets[0].datasetlink2}
                            onChange={this.handleDatasetChange}
                            rows="1"
                          ></textarea>
                          <label>Link title 2</label>
                          <textarea
                            type="text"
                            name="datasetlinktitle2"
                            placeholder="Dataset Link Title"
                            value={this.state.datasets[0].datasetlinktitle2}
                            onChange={this.handleDatasetChange}
                            rows="1"
                          ></textarea>

                      
                        </div>
                        <div className="pure-u-1-1">
                        <label>context</label>
                          <textarea
                            type="text"
                            name="context"
                            placeholder="context"
                            value={this.state.datasets[0].context}
                            onChange={this.handleDatasetChange}
                            rows="4"
                          ></textarea>
                          <label>intended use</label>
                          <textarea
                            type="text"
                            name="intendeduse"
                            placeholder="intended use"
                            value={this.state.datasets[0].intendeduse}
                            onChange={this.handleDatasetChange}
                            rows="4"
                          ></textarea>

                          <label>Ethical Considerations</label>
                          <textarea
                            type="text"
                            name="ethicalconsiderations"
                            placeholder="Ethical Considerations"
                            value={this.state.datasets[0].ethicalconsiderations}
                            onChange={this.handleDatasetChange}
                            rows="4"
                          ></textarea>

                          <label>Links to publications</label>
                          <textarea
                            type="text"
                            name="publications"
                            placeholder="Publications and papers"
                            value={this.state.datasets[0].publications}
                            onChange={this.handleDatasetChange}
                            rows="4"
                          ></textarea>
                    
                        </div>
                        </div>
                        <br /><br />
                       
                        
                      </form>
                      <button
                      className="button-plain button-plain-black"
                      onClick={this.updateDataset}
                    >
                      update dataset
                    </button>
                    </div>
                    ):(
                      <form onSubmit={this.newdatasetsave}>
                      <input
                        id="project-title-input"
                        type="text"
                        name="title"
                        placeholder="dataset title"
                        value={this.state.dataset.title}
                        onChange={this.handleDatasetChange}
                      ></input>
                      <input
                        id="project-subtitle-input"
                        type="text"
                        name="subtitle"
                        placeholder="dataset subtitle"
                        value={this.state.dataset.subtitle}
                        onChange={this.handleDatasetChange}
                      ></input>
                      <div className="pure-g">
                        <div className="pure-u-1-2 details-box">
                          <label>dataset size</label>
                          <textarea
                            type="text"
                            name="size"
                            placeholder="dataset size"
                            value={this.state.dataset.size}
                            onChange={this.handleDatasetChange}
                            rows="1"
                          ></textarea>

                          <label>data type</label>
                          <textarea
                            type="text"
                            name="datatype"
                            placeholder="data type"
                            value={this.state.dataset.datatype}
                            onChange={this.handleDatasetChange}
                            rows="1"
                          ></textarea>
                          <label>data format</label>
                          <textarea
                            type="text"
                            name="dataformat"
                            placeholder="data format"
                            value={this.state.dataset.format}
                            onChange={this.handleDatasetChange}
                            rows="1"
                          ></textarea>
                        </div>
                        <div className="pure-u-1-2 details-box">
                          <label>data owners</label>
                          <textarea
                            type="text"
                            name="dataowner"
                            placeholder="data owners"
                            value={this.state.dataset.dataowner}
                            onChange={this.handleDatasetChange}
                            rows="1"
                          ></textarea>

                          <label>acknowledgements</label>
                          <textarea
                            type="text"
                            name="acknowledgements"
                            placeholder="acknowledgements"
                            value={this.state.dataset.acknowledgements}
                            onChange={this.handleDatasetChange}
                            rows="1"
                          ></textarea>

                          <label>demo colab notebook</label>
                          <textarea
                            type="text"
                            name="colablink"
                            placeholder="colab notebook"
                            value={this.state.dataset.colablink}
                            onChange={this.handleDatasetChange}
                            rows="1"
                          ></textarea>

                          <label>Link to public dataset</label>
                          <textarea
                            type="text"
                            name="datasetlink"
                            placeholder="Dataset Link"
                            value={this.state.dataset.datasetlink}
                            onChange={this.handleDatasetChange}
                            rows="1"
                          ></textarea>
                        </div>
                        <div className="pure-u-1-1">
                          <label>context</label>
                          <textarea
                            type="text"
                            name="context"
                            placeholder="context"
                            value={this.state.dataset.context}
                            onChange={this.handleDatasetChange}
                            rows="4"
                          ></textarea>
                          <label>intended use</label>
                          <textarea
                            type="text"
                            name="intendeduse"
                            placeholder="intended use"
                            value={this.state.dataset.intendeduse}
                            onChange={this.handleDatasetChange}
                            rows="4"
                          ></textarea>

                          <label>Ethical Considerations</label>
                          <textarea
                            type="text"
                            name="ethicalconsiderations"
                            placeholder="Ethical Considerations"
                            value={this.state.dataset.ethicalconsiderations}
                            onChange={this.handleDatasetChange}
                            rows="4"
                          ></textarea>

                          <label>Links to publications</label>
                          <textarea
                            type="text"
                            name="publications"
                            placeholder="Publications and papers"
                            value={this.state.dataset.publications}
                            onChange={this.handleDatasetChange}
                            rows="4"
                          ></textarea>
                          
                        </div>
                      </div>
                      <button
                  className="button-plain button-plain-black"
                  onClick={this.saveDataset}
                >
                  save dataset
                </button>
                    </form>
                    )}
                    
                    
                  </TabPanel>

                  <TabPanel>
                    <p>connect dataset</p>
                      
                  </TabPanel>
                </Tabs>

                <br />
                <br />

                <br />
                <button
                  className="button-plain button-plain-black"
                  onClick={this.updateProject}
                >
                  save project
                </button>
              </form>
            </div>{" "}
          </div>
        )}
      </div>
    );
  }
}

export default ProjectEditor;
