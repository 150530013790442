import React from 'react'
import moment from 'moment'


const MediumItem = ({title, pubDate, thumbnail, link}) => (
   
        <div className="pure-u-1-3">
            <div className="card medium-card">
                { thumbnail
                ? (<div className="medium-image">
                
                        <img src={thumbnail} />
                   
                    </div>)
                : "" }

                <div >
                 
                    <h1>{title}</h1>
                  
                </div>
                
                <div>
                    
                        <span >{moment(pubDate).format('MMM Do')} {moment(pubDate).format('YYYY')}</span>
                  
                
                   <br />
                   
                    <a  target="_blank" href={link}>
                        Read on our Medium
                    </a>
                
                </div>
                </div>
            </div>

   
         

 
   
  )

  export default MediumItem