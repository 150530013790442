import React from "react";
import {
  Link
} from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../utils/falibrary";

class TopNav extends React.Component {
    constructor(props){
        super(props);
        this.state = {};

        this.openProfileDropdown = this.openProfileDropdown.bind(this);
        this.closeProfileDropdown = this.closeProfileDropdown.bind(this);
    }

    openProfileDropdown() {
        console.log("open profile dropdown");
        this.setState({
          showProfileDropdown: true,
        });
      }

      closeProfileDropdown() {
        console.log("close profile dropdown");
        this.setState({
          showProfileDropdown: false,
        });
      }

    render(){
        return (
            
            <nav className="top-nav">
                 <Link to="/">
                     {this.props.isHelio ?(
                         <img id="top-nav-logo" src="../images/helio-logo.png" />
                     ):(
                        <img id="top-nav-logo" src="../images/logo-black.png" />
                     )}
                    
                </Link>
                <ul className="main-nav">

                    {!this.props.isAuth ? (
                        <div>
                            <li>
                                <a onClick={this.props.openRegister}> Sign Up </a>
                            </li>

                            <li>
                                <a onClick={this.props.openLogin}> Log In </a>
                            </li>
                        </div>
                    ):(
                        <li>
                            <a onMouseOver={this.openProfileDropdown}>
                    <FontAwesomeIcon icon={["fas", "user-circle"]} />
                    &nbsp;&nbsp;
                    {this.props.user.username}
                  </a>
                  {this.state.showProfileDropdown ? (
                    <div
                      className="profile-dropdown"
                      onMouseLeave={this.closeProfileDropdown}
                    >
                      <Link
                        to={{ pathname: `/userprofile/${this.props.user.id}` }}
                      >
                        Profile Page
                      </Link>

                      <a onClick={this.props.logoutUser}> Logout </a>
                    </div> ): null}
                            
                        </li>
                    )
                    
                    }
                    
                    <li>
                        <span>|</span>
                    </li>
                       
                    <li>
                        <Link to="/about" className="about-link">
                        <FontAwesomeIcon icon={["fas", "question-circle"]} />
                        &nbsp;&nbsp;About SpaceML
                        </Link>
                    </li>
                    <li >
                        <Link to="/speakerseries" className="explore-link">
                        <FontAwesomeIcon icon={["fas", "video"]} />
                        &nbsp;&nbsp;Speaker Series
                        </Link>
                    </li>


                    <li >
                        <Link to="/repo" className="explore-link">
                        <FontAwesomeIcon icon={["fas", "layer-group"]} />
                        &nbsp;&nbsp;Explore ML Projects & Datasets
                        </Link>
                    </li>

                   
                    

                </ul>
            </nav>
        )
        
    }
}

export default TopNav;