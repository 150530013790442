import React from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";

class LoginUser extends React.Component{
    constructor(props){
        super(props)
        this.state={
            showModal: false,
            email: "",
            password: "",
            errors: {}
        }

        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
    }

    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
      };

    onSubmit = e => {
        e.preventDefault();
            const userData = {
                email: this.state.email,
                password: this.state.password
            };
            console.log(userData);
            axios.post((process.env.REACT_APP_API_IP || "http://localhost:9000") +"/loginUser", userData)
                .then(res => {
                    console.log(res)
                  const{ token } = res.data;
                  localStorage.setItem("jwtToken",token);
                  // Set token to Auth header
                  setAuthToken(token);
                  // Decode token to get user data
                  const decoded = jwt_decode(token);
                  //set current user
                  
                  this.props.setCurrentUser(decoded);
                  this.setState({ showModal: false });
                  this.props.welcomeback();
                  
                }) // re-direct to login on successful register
                .catch(err => console.log(err));
    };

    handleOpenModal () {
        this.setState({ showModal: true });
    }

    handleCloseModal () {
        this.setState({ showModal: false });
    }

    render(){

        const { errors } = this.state;
        return(
            <Modal
            closeTimeoutMS={500}
            isOpen={this.state.showModal} 
            onRequestClose={this.handleCloseModal}
            shouldCloseOnEsc={true} 
            overlayClassName={"userform-overlay"}
            className="user-form-content"
            
            >
              <h1>Log in to SpaceML</h1>
              <form  className="userform" noValidate onSubmit={this.onSubmit}>
              <label htmlFor="email">📧 Email</label>
                <input
                  onChange={this.onChange}
                  value={this.state.email}
                  error={errors.email}
                  id="email"
                    type="email"
                    placeholder="email"
                    autoComplete="email"
                />
                
            
                <label htmlFor="password">🔑 Password</label>
                <input
                  onChange={this.onChange}
                  value={this.state.password}
                  error={errors.password}
                  id="password"
                    type="password"
                    placeholder="password"
                    autoComplete="password"
                />
                

             

                <div  style={{ textAlign:"center"}}>
                    <button
                    
                    type="submit"
                    className="button-plain button-plain-black">
                    Log in
                  </button>
                  </div>
            
            </form>
            </Modal>
        )
    }
}

export default LoginUser;