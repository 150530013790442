import React from "react";
import axios from "axios";
import Modal from "react-modal";

import { useHistory } from "react-router-dom";

class RegisterUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      email: "",
      password: "",
      password2: "",
      fullname: "",
      gitlink: "",
      errors: {},
      showModal: false,
      step: 1,
      agreed: false,
    };

    this.success = this.success.bind(this);
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.registerSuccess = this.registerSuccess.bind(this);
    this.onStepdone = this.onStepdone.bind(this);
    this.deciderender = this.deciderender.bind(this);
  }

  onRequestClose() {
    this.setState({ showModal: false });
  }

  handleOpenModal() {
    this.setState({ showModal: true });
  }

  handleCloseModal() {
    this.setState({ showModal: false });
  }

  registerSuccess() {
    this.setState({ showModal: false });
    this.props.next();
  }

  onStepdone() {
    this.setState({ step: this.state.step + 1 });
  }

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onSubmit = (e) => {
    e.preventDefault();

    const newUser = {
      username: this.state.username,
      display: this.state.username,
      email: this.state.email,
      password: this.state.password,
      gitlink: this.state.gitlink,
      fullname: this.state.fullname,
    };
    console.log(newUser);
    axios
      .post(
        (process.env.REACT_APP_API_IP || "http://localhost:9000") +
          "/registerUser",
        newUser
      )
      .then((res) => this.success()) // re-direct to login on successful register
      .catch((err) => console.log(err));
  };

  success = (e) => {
    this.registerSuccess();
  };

  deciderender() {
    const { errors } = this.state;
    let obj;
    if (this.state.step === 1) {
      obj = (
        <div id="register-step-1">
          <h2>
            Step 1 of 3: <b>Account Details</b>{" "}
          </h2>
          <form className="userform" noValidate onSubmit={this.onSubmit}>
            <label htmlFor="name">🙂 Username (Required)</label>
            <input
              onChange={this.onChange}
              value={this.state.username}
              error={errors.username}
              id="username"
              type="text"
              placeholder="username"
              autoComplete="username"
            />

            <label htmlFor="email">📧 Email (Required)</label>
            <input
              onChange={this.onChange}
              value={this.state.email}
              error={errors.email}
              id="email"
              type="email"
              placeholder="email"
              autoComplete="email"
            />

            <label htmlFor="password">🔑 Password (Required)</label>
            <input
              onChange={this.onChange}
              value={this.state.password}
              error={errors.password}
              id="password"
              type="password"
              placeholder="password"
              autoComplete="password"
            />

            <label htmlFor="password2">🔒 Confirm Password (Required)</label>
            <input
              onChange={this.onChange}
              value={this.state.password2}
              error={errors.password2}
              id="password2"
              type="password"
              placeholder="confirm password"
            />

            <div style={{ textAlign: "center" }}>
              <button
                onClick={this.onStepdone}
                // type="submit"
                className="button-plain button-plain-black"
              >
                NEXT STEP
              </button>
            </div>
          </form>
        </div>
      );
    } else if (this.state.step === 2) {
      obj = (
        <div id="register-step-3">
          <h2>
            Step 2 of 3: <b>Account Info</b> <br /> <br /> OPTIONAL{" "}
          </h2>

          <form className="userform" noValidate onSubmit={this.onSubmit}>
            <label htmlFor="fullname">📋 Full name</label>
            <input
              onChange={this.onChange}
              value={this.state.fullname}
              error={errors.fullname}
              id="fullname"
              type="text"
              placeholder="full name"
              autoComplete="fullname"
            />

            <label htmlFor="gitlink">💻 gitlab / github link</label>
            <input
              onChange={this.onChange}
              value={this.state.gitlink}
              error={errors.gitlink}
              id="gitlink"
              type="gitlink"
              placeholder="gitlink"
              autoComplete="gitlink"
            />
            <div style={{ textAlign: "center" }}>
              <button
                onClick={this.onStepdone}
                // type="submit"
                className="button-plain button-plain-black"
              >
                NEXT STEP
              </button>
            </div>
          </form>
        </div>
      );
    } else {
      obj = (
        <div id="register-step-3">
          <h2>
            Step 3 of 3: <b>User Agreement</b>
          </h2>
          <div>
            <b>To access SpaceML enhanced data, MLOPs and notebooks you are required to read and accept our user and data policy agreement</b>
          </div>
          <br></br>
          <div className="terms-text">
          <b>SpaceML Terms and Conditions</b>
<br />
<br />
Copyrights
<br />
<br />
SpaceML projects contain images and movies are not copyrighted unless explicitly noted. The use of datasets for non-commercial purposes and public education and information efforts is strongly encouraged and requires no expressed authorization. It is requested, however, that any such use properly attributes the source of the images or data using DOI’s or as stated below:
<br />
"Courtesy of NASA/SDO and the AIA, EVE, and HMI science teams."
<br />
<br />
The SpaceML project data users agree to abide the Rules of the Road developed for scientists and science enthusiasts alike 
These are: 
<br />
<br />
SpaceML Project Leads (PL) will endeavor to make available to the data user community (DUC) the same access methods to reach the data and tools as the original project team uses.
<br />
<br />
The PL will provide notification of updates to processing software and calibrations via metadata and other appropriate documentation as necessary. 
All new members of the DUC shall have to request access to the project via the project request data form.  DUC may need to consult with the PL to ensure that they are accessing the most recent available versions of the data and analysis routines.
The SpaceML team will carry out dataset information updates on a regular basis to ensure that all data present on the website is accurate and time stamped. 
<br />
<br />
Colab notebooks are intended for small simulations and should only be used to further project analysis or publication with the consent of the PL.
DUC shall acknowledge the sources of data used in all publications and reports.  We strongly encourage the use of DOI’s to attribute the source of data. 
DUC shall include in publications the information necessary to allow others to access the particular data used. Additionally, all new daughter datasets generated through research will be registered with SpaceML and appropriate DOI’s applied. 
<br />
<br />
It is strongly encouraged that any new project work generated from the existing datasets, be shared with the original Project Lead in the form of a copy of the manuscript that uses the PL's data.
<br />
<br />
DUC are encouraged to communicate any improvements in the tools used for analysis to the wider community.  Furthermore, they are encouraged to make these tools accessible where possible. 
<br />
<br />
The editors and referees of scientific journals should avail themselves of the expertise of the Project Lead where appropriate, when  a data set is still unfamiliar to the community, and when it is uncertain whether authors have employed the most up-to-date data and calibrations.
<br />
<br />
Disclaimer Statement 
<br />
<br />
Last updated: May 27, 2021
<br />
<br />
SpaceML.org and its administrative partners, the SETI Institute and Trillium Technologies Inc, and funding organizations assume no responsibility for errors or omissions in the provision of the SpaceML service.
In no event shall the partners be liable for any special, direct, indirect, consequential, or incidental damages or any damages whatsoever, whether in an action of contract, negligence or other tort, arising out of or in connection with the use of the data or the any other contents of the service, such as code. SpaceML’s administrative partners reserve the right to make additions, deletions, or modifications to the contents on SpaceML at any time without prior notice. Similarly SpaceML does not warrant that the service is free of viruses or other harmful components.
<br />
<br />
External Links Disclaimer
SpaceML may contain links to external websites that are not provided or maintained by or in any way affiliated with the administrative partners. 
<br />
<br />
Please note that the SpaceML.org does not guarantee the accuracy, relevance, timeliness, or completeness of any information on these external websites.
<br />
<br />
Errors and Omissions Disclaimer
<br />
<br />
SpaceML and its administrative partners is not responsible for any errors or omissions, or for the results obtained from the use of this information.
<br />
<br />
The information and data given by SpaceML is for general guidance only. Even if the SpaceML takes every precaution to ensure that the content of the repo is both current and accurate, errors can occur. Plus, given the changing nature of laws, rules and regulations, there may be delays, omissions or inaccuracies in the information contained within SpaceML.
<br />
<br />
Fair Use Disclaimer
SpaceML and its administrative partners may use copyrighted material which has not always been specifically authorized by the copyright owner (such as diagrams and figures) SpaceML is making such material available for teaching, scholarship, or research and will always endeavor to provide accreditation. 
<br />
<br />
SpaceML believes this constitutes a "fair use" of any such copyrighted material as provided for in section 107 of the United States Copyright law.If you wish to use copyrighted material from SpaceML for your own purposes that go beyond fair use, you must obtain permission from the copyright owner.
<br />
<br />
Views Expressed Disclaimer
The SpaceML’s comments feature may contain views and opinions which are those of the authors and do not necessarily reflect the official policy or position of any other author, agency, organization, employer or company, including the SpaceML and its administrative partners.
<br />
<br />
Comments published by users are their sole responsibility and the users will take full responsibility, liability and blame for any libel or litigation that results from something written in or as a direct result of something written in a comment. SpaceML and its administrative partners are not liable for any comment published by users and reserve the right to delete any comment for any reason whatsoever.
<br />
<br />
No Responsibility Disclaimer
In no event shall SpaceML and its administrative partners or its suppliers be liable for any special, incidental, indirect, or consequential damages whatsoever arising out of or in connection with your access or use or inability to access or use the data and code products. 
<br />
<br />
The information, data and code on the SpaceML is provided with the understanding that the tools on SpaceML are herein engaged in rendering professional advice and services. As such, any outcomes, predictions or machine learning results should not be used as a substitute for consultation with professional researchers or other competent advisers.
"Use at Your Own Risk" Disclaimer
<br />
<br />
All data and code on SpaceML is provided "as is", with no guarantee of completeness, accuracy, timeliness or of the results obtained from the use of this information, and without warranty of any kind, express or implied, including, but not limited to warranties of performance, merchantability and fitness for a particular purpose.
SpaceML and its administrative partners will not be liable to you or anyone else for any decision made or action taken in reliance on the information given by the machine learning results or for any consequential, special or similar damages, even if advised of the possibility of such damages.
<br />
<br />
Definitions
<br />
<br />
For the purposes of this Disclaimer:
<br />
<br />
	•	SpaceML.org (referred to as either "SpaceML", "We", "Us" or "Our" in this Disclaimer) refers to Trillium Technologies , 70 West Madison St Chicago, IL. USA.
  <br />
<br />
	•	Service refers to the Website and repo. 
  <br />
<br />
	•	You means the individual accessing the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.
  <br />
<br />
	•	Website refers to SpaceML.org , accessible from http://spaceml.org
Contact Us
<br />
<br />
If you have any questions about this Disclaimer, You can contact Us:
<br />
<br />
	•	By email: info@fdl.ai
  <br />
<br />
I acknowledge I have read and understand the terms of this Data Policy



          </div>
          <br></br>
          <b>
            If you have any questions let us know at{" "}
            <a style={{ color: "#6e75b4" }} href="anchal@fdl.ai">
              anchal@fdl.ai
            </a>
          </b>
          <br></br>
          <br></br>
          <div className="accept-block">
            <input
              type="checkbox"
              onClick={() => this.setState({ agreed: true })}
            ></input>
            <span>
              <b>I have read and accept</b>
            </span>
          </div>
          <br></br>
          <br></br>
          <div style={{ textAlign: "center" }}>
            <button
              // type="submit"
              onClick={this.onSubmit}
              className="button-plain button-plain-black"
              style={{ display: this.state.agreed ? "block" : "none" }}
            >
              Complete Registration
            </button>
          </div>
        </div>
      );
    }
    return obj;
  }

  render() {
    const { errors } = this.state;
    let object_to_be_rendered = this.deciderender();
    return (
      <Modal
        closeTimeoutMS={500}
        isOpen={this.state.showModal}
        onRequestClose={this.handleCloseModal}
        shouldCloseOnEsc={true}
        overlayClassName={"userform-overlay"}
        className="user-form-content"
      >
        <h1>Create your SpaceML account</h1>
        {object_to_be_rendered}
      </Modal>
    );
  }
}

export default RegisterUser;
