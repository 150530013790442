import React from "react";
import ReactGA from "react-ga4";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import axios from "axios";
import {Helmet} from "react-helmet";

import {
  LinkedinShareButton,
  TwitterShareButton,
  LinkedinIcon,
  TwitterIcon,
  LineShareButton,
} from "react-share";

import Spinner from "react-spinkit";
import Dataset from "./Dataset";


import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
} from "react-router-dom";

ReactGA.initialize("G-S10ZRX1FCX");
class Project extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      projectId: props.match.params.projectId,
      showdatasetfirst: props.match.params.showdataset,
      project: {},
      creator: {},
      cover: {},
      coverloaded: false,
      focus: 0,
      tabIndex: 0,
      datasets: [],
      isauth: false
    };

    this.getCoverImage = this.getCoverImage.bind(this);
    this.getProject = this.getProject.bind(this);
    this.removeProject = this.removeProject.bind(this);
    this.setTabIndex = this.setTabIndex.bind(this);
    this.getDatasets = this.getDatasets.bind(this);
    this.checkifshowingdataset = this.checkifshowingdataset.bind(this);
    this.colabclick = this.colabclick.bind(this);
    this.gitclick = this.gitclick.bind(this);
    this.webclick = this.webclick.bind(this);
  }

  getCoverImage() {
    axios
      .get(
        (process.env.REACT_APP_API_IP || "http://localhost:9000") +
          "/getImage",
        {
          params: {
            id: this.state.project.coverimage,
          },
        }
      )
      .then((res) => {
        if (res.data) {
          this.setState({
            cover: res.data.imagedata,
            coverloaded: true,
          });
        }
      });
  }

  getCreator() {
    axios
      .get(
        (process.env.REACT_APP_API_IP || "http://localhost:9000") +
          "/getUser",
        {
          params: {
            id: this.state.project.creator_id,
          },
        }
      )
      .then((res) => {
        if (res) {
          this.setState({
            creator: res.data,
          });
        }
      });
  }

  getProject() {
    axios
      .get(
        (process.env.REACT_APP_API_IP || "http://localhost:9000") +
          "/getProject",
        {
          params: {
            id: this.state.projectId,
          },
        }
      )
      .then((res) => {
        if (res.data) {
          this.setState({
            project: res.data,
          });
          ReactGA.event({
            category: 'action',
            action: 'Open Project'
          });
          ReactGA.event({
            category: 'action',
            action: this.state.project.subject
          });
          ReactGA.send({ hitType: "pageview", page: this.state.project.title });
  
          this.getCoverImage();
          this.getCreator();
          this.getDatasets();
        } else {
          console.log("the response had no data");
        }
      });
  }

  colabclick(){
   
    ReactGA.event({
      label: this.state.project.subject,
      category: 'action',
      action: "colab link click"
    });
  }

  webclick(){
   
    ReactGA.event({
      label: this.state.project.subject,
      category: 'action',
      action: "web link click"
    });
  }

  gitclick(){
    ReactGA.event({
      category: 'action',
      action: "git link click"
    });
  }

  getDatasets() {
    console.log(this.state.project.datasets);
    axios
      .get(
        (process.env.REACT_APP_API_IP || "http://localhost:9000") +
          "/getDatasetsById",
        {
          params: {
            ids: this.state.project.datasets,
          },
        }
      )
      .then((res) => {
        if (res.data) {
          this.setState({
            datasets: res.data,
          });

          console.log("datasets got!");
          console.log(this.state.datasets);
        }
      });
  }

  removeProject() {
    axios
      .post(
        (process.env.REACT_APP_API_IP || "http://localhost:9000") +
          "/removeProject",
        {
          id: this.state.projectId,
        }
      )
      .then((res) => {
        if (res.data) {
          console.log("removed");
          // window.location.href = "http://spaceml.org:3000/";
        }
      })
      .catch((err) => console.log(err));
  }

  checkifshowingdataset() {
    console.log("showing dataset!!!!?");
    console.log(this.state.showdatasetfirst);
    if (this.state.showdatasetfirst === "true") {
      this.setState({
        tabIndex: 1,
      });
    }
  }
  setTabIndex(index) {
    this.setState({
      tabIndex: index,
    });
  }

  componentDidMount() {
    console.log("got user from props location!");
    // console.log(this.props.location.state.isauth);
    // this.setState({
    //   user: this.props.location.state.user,
    //   isauth: this.props.location.state.isauth,
    // });
    this.getProject();
    this.checkifshowingdataset();
  }

  componentDidUpdate(){
   
    if(this.props.location.state !== undefined){
      console.log("does exist")
      if( this.props.location.state.isauth !== this.state.isauth){
        
        this.setState({
      
          isauth: this.props.location.state.isauth,
          });

      }
      
    } else {
      console.log("doesnt exist")
    }
    // console.log(this.props.location.state.isauth);
    
  }

  render() {
    var boxBarStyle = {
      backgroundColor: this.state.project.color,
    };
    var projectHeaderStyle = {
      backgroundColor: "black",
      backgroundImage:
        'linear-gradient(to bottom, rgba(0,0,0,0.8), rgba(0,0,0,0.1)), url("' +
        this.state.cover +
        '")',
      minHeight: "100px",
    };
    return (
      <div>
          <Helmet>
                <meta charSet="utf-8" />
                <title>{this.state.project.title ? this.state.project.title : "spaceml project"}</title>
          </Helmet>
        {/* <div className="project-holder extra-max-width new-project-holder">
          <div className="pure-g">
            <div className="pure-u-1-3">
            <h1>{this.state.project.title}</h1>
            <h2>{this.state.project.subtitle}</h2>
            </div>
            <div className="pure-u-2-3 project-summaries">
              <div className="pure-g ">
                <div className="pure-u-1-2 ">
                  <span>team</span>
                  <h3>Researchers:</h3>
                  <h4>{this.state.project.researchers}</h4>

                  <h3>Faculty:</h3>
                  <h4>{this.state.project.mentors}</h4> 
                </div>
                <div className="pure-u-1-2"> 
                <span>details</span>
                <h3>Program: </h3>
                <h4>{this.state.project.programme} </h4>
                <h3>Institution: </h3>
                <h4>{this.state.project.institution} </h4>
                <h3>Year: </h3>
                <h4>{this.state.project.year}</h4>
                <h3>Challenge area: </h3>
                <h4>{this.state.project.subject}</h4>
                </div>
              </div>
            </div>
          
          </div>
          <div className="pure-g">
            <div className="pure-u-1-4 project-summaries">
              <span>Resources:</span>
              {this.state.project.hascolablink == true ? (
                  <a
                    href={this.state.project.colablink}
                    
                    target="_blank"
                  >
                  
                    Demo Colab Notebook
                    <br />
                  </a> 
                ) : (
                  <b>&nbsp; </b>
                )}

                {this.state.project.weblink ? (
                  <a
                    href={this.state.project.weblink}
                   
                    target="_blank"
                  >
                    Open Project Website
                    <br />
                  </a>
                ) : (
                  <b>&nbsp; </b>
                )}

                {this.state.project.gitlink ? (
                  <a
                    href={this.state.project.gitlink}
                   
                    target="_blank"
                  >
                   View on github
                    <br />
                  </a>
                ) : (
                  <b>&nbsp; </b>
                )}
            </div>
            <div className="pure-u-1-2">
            <div
                      className="overview-text overview-content"
                      dangerouslySetInnerHTML={{
                        __html: this.state.project.summary,
                      }}
                    ></div>
            </div>
            <div className="pure-u-1-4 project-summaries">
              <span>Datasets</span>

              </div>
          </div>
        </div> */}


        {this.state.coverloaded ? (
          <div className="project-list-header" style={projectHeaderStyle}></div>
        ) : (
          <div
            className="project-list-header project-spinner-holder"
            style={projectHeaderStyle}
          >
            <Spinner
              color="white"
              className="project-cover-spinner"
              fadeIn="none"
              name="three-bounce"
            />
          </div>
        )}

        <div className="project-title-box-bar" style={boxBarStyle}></div>
        <div className="project-holder max-width">
          <div className="pure-g">
            <div className="pure-u-2-3">
              <div className="bread-crumbs">
              <a href="http://spaceml.org">repo</a> &rarr; project
            </div>
            <h1>{this.state.project.title}</h1>
            

            <h2>{this.state.project.subtitle}</h2>
        
           
            {this.props.user1 == "leosilverberg" ? (
              <Link
                to={{
                  pathname: "/repo/projecteditor",
                  state: {
                    project: this.state.project,
                    datasets: this.state.datasets,
                  },
                }}
                className=""
              >
                edit me
              </Link>
            ) : (
              <p></p>
            )}
              </div>
              <div className="pure-u-1-3">
              <div className="share-tab-wrapper">
                    <div className="share-text">Share Project</div>
                    <div className="share-tab">
                      <TwitterShareButton
                        url={window.location.href}
                        title={`Project: ${this.state.project.title}`}
                      >
                        <TwitterIcon size={45} />
                      </TwitterShareButton>
                    </div>
                    <div className="share-tab">
                      <LinkedinShareButton
                        url={window.location.href}
                        title={`Project: ${this.state.project.title}`}
                      >
                        <LinkedinIcon size={45} />
                      </LinkedinShareButton>
                    </div>
                  </div>
              </div>
          </div>
          

          {/* <Link to={{ pathname: `/repo/projecteditor/${this.state.projectId}` }} className="">edit me</Link> */}

          <div className="pure-g project-summaries">
            <div className="pure-u-1-2 team-box">
              <span>team</span>
              <h3>Researchers:</h3>
              <h4>{this.state.project.researchers}</h4>

              <h3>Faculty:</h3>
              <h4>{this.state.project.mentors}</h4>
              <h3>Posted by:</h3>
              <h4>
                <Link
                  to={{
                    pathname: `/userProfile/${this.state.project.creator_id}`,
                  }}
                >
                  {this.state.creator.username}
                </Link>
              </h4>
            </div>
            <div className="pure-u-1-2 details-box">
              <span>details</span>
              <h3>Program: </h3>
              <h4>{this.state.project.programme} </h4>
              <h3>Institution: </h3>
              <h4>{this.state.project.institution} </h4>
              <h3>Year: </h3>
              <h4>{this.state.project.year}</h4>
              <h3>Challenge area: </h3>
              <h4>{this.state.project.subject}</h4>
            </div>
          </div>

          <div className="">
            <Tabs
              selectedIndex={this.state.tabIndex}
              onSelect={(index) => this.setTabIndex(index)}
            >
              <TabList>
                <Tab>Overview</Tab>

                <Tab>Datasets</Tab>
          
                {this.state.project.hascolablink == true ? (
                  <a
                  href={this.state.project.colablink}
                  target="_blank"
                  className="colab-button"
                  onClick={this.colabclick}
                  >
                 <img src="/images/colab_icon.png" />
                    &nbsp;Open in COLAB
                </a>
              
                ) : (
                  <b>&nbsp; </b>
                )}

                {this.state.project.weblink ? (
                  <a 
                  href={this.state.project.weblink}
                  target="_blank"
                  className="website-button"
                  onClick={this.webclick}
                  >
                 
                 &nbsp;Open Project Website
                </a>
                 
                ) : (
                  <b>&nbsp; </b>
                )}
                {this.state.project.gitlink ? (
                  <a
                  href={this.state.project.gitlink}
                  target="_blank"
                  className="website-button"
                  onClick={this.gitclick}
                  >
                
                 &nbsp;Open in Github / Gitlab
                </a>
                  
                ) : (
                  <b>&nbsp; </b>
                )}
            
              </TabList>

              <TabPanel>
                <div className="pure-g overview-content medium-width ">
                  <div className="pure-u-1-1 ">
                    <div
                      className="overview-text"
                      dangerouslySetInnerHTML={{
                        __html: this.state.project.summary,
                      }}
                    ></div>
                  </div>
                </div>
              </TabPanel>

              <TabPanel>
                <Dataset openRegister={this.props.openRegisterModal} dataset={this.state.datasets} isauth={this.props.isauth} user={this.props.match.params.username} subject={this.state.project.subject}/>
              </TabPanel>
              <TabPanel>
                <p>Try it out here</p>
              </TabPanel>
              <TabPanel>
                <p>Provide feedback here </p>
              </TabPanel>
            </Tabs>
          </div>
          {/*             
            <h3>I'm a project: {this.state.projectId}</h3> 
        <h2>the title is: {this.state.project.title}</h2>
        <br /> */}
{/* 
          <a href="#" onClick={this.removeProject}>
      delete me
        </a> */}
        </div>
      </div>
    );
  }
}

export default Project;
