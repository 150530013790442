import React from 'react';
import ProjectCard from '../components/ProjectCard';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch
  } from "react-router-dom";
import axios from 'axios';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import '../utils/falibrary';

class UserProfile extends React.Component{
    constructor(props){
        super(props)
        this.state={
            userId: props.match.params.userId,
            user:{},
            projects:[]
        }

        this.getUser = this.getUser.bind(this);
        this.getProjects = this.getProjects.bind(this);


    }

    getProjects(){
        axios.get((process.env.REACT_APP_API_IP || "http://localhost:9000") +"/getProjectsByUser",{
            params:{
                user_id: this.state.userId
            }
        })
        .then(res =>{
            this.setState({
                projects: res.data
            })
        })
    }

    getUser(){
     
        axios.get((process.env.REACT_APP_API_IP || "http://localhost:9000") +"/getUser",{
            params: {
                id: this.state.userId
            }
        })
        .then(res =>{
            if(res){
                
                this.setState({
                    user:res.data
                })
         
            }
        })
    }

    componentDidMount(){
        this.getUser();
        this.getProjects();
    }

    render(){
        var userHeaderStyle ={
            backgroundImage:'linear-gradient(to right, #360033,  #0b8793), url("'+this.state.cover+'")',
            minHeight:'80px'
        }
        var whiteBackground ={
            backgroundColor:'white'
        }
        return(
        <div style={{backgroundColor:'white'}}> 
            <div className="user-list-header" style={userHeaderStyle}></div>
            <div className="user-holder medium-width">
                <div className="user-profile-details">
                <h1>{this.state.user.username}</h1>
                <h2>{this.state.user.fullname}</h2>
                <h2><a href={this.state.user.gitlink}>{this.state.user.gitlink}</a></h2>
                </div>
                

                <Tabs>
                <TabList>
                    <Tab><FontAwesomeIcon icon={['fas','book']} />&nbsp;&nbsp;Projects</Tab>
                  
                    {/* <Tab>Models & Data</Tab> */}
                    {/* <Tab>On GoogleCloud</Tab> */}
                </TabList>

                <TabPanel>
                    <div className="pure-g  ">
                        <div className="pure-u-1-1 ">
                            <div className="user-profile-project-list pure-g" >
                                
                            {
                                    this.state.projects && 
                                        this.state.projects.length > 0 ?(
                                            this.state.projects.map(project =>{
                                                return(
                                                        <Link to={{pathname: `/project/${project._id}`}} className="pure-u-xl-1-4 pure-u-lg-1-3 pure-u-md-1-2 pure-u-sm-1-1 pure-u-1-1">
                                                            <ProjectCard project={project} />
                                                        </Link>

                                                )
                                            })
                                        )
                                        :
                                        (
                                            <div className="pure-u-1-1">
                                            <br />
                                            <p>This user has no projects, so far</p>
                                            </div>
                                        )
                                }
                                
                                

                            </div>
                        </div>
                      
                    </div>
                </TabPanel>

               

            </Tabs>
            </div>
        </div>
        )
    }

}

export default UserProfile;