import React from "react";
import {
  Link
} from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../utils/falibrary";

class Speakerseries extends React.Component {
    constructor(props){
        super(props);
        this.state = {};
    }

    render(){
        return (
            
            <div className="landing-page-container">
                <div className="about-page-top landing-page-dark ">
                <div className="landing-page-about max-width pure-g">
                    <div className="pure-u-1-2 ">
                      <div className="pure-g">
                          <div className="pure-u-1-2">
                          <img src="\images\speakerserieslogo.png" className="pure-img"/>
                          </div>
                      </div>
                   
                    
                        <p>
                            <br />
                            The ambition of these talks is to bring useful learning to the greater community and to help researchers onboard open science data and pipelines more rapidly.
                        </p>
                        <p>
                            <br />
                            You can find our Youtube channel here: <a href="https://www.youtube.com/channel/UCxI8ZDo3Gs33l3FTujx_TpQ">SpaceML on Youtube</a>
                        </p>
                    </div>

                    </div>
                    </div>

                    <div className="landing-page-middle landing-page-light" >
        <div className="landing-page-about max-width pure-g">
            <div className="pure-u-1-1 ">
                <h1>Latest speaker series videos</h1>
                <div className="pure-g">
              <div className="pure-u-1-3">
              <iframe width="90%" height="315" src="https://www.youtube.com/embed/rhxyC9MkWGU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
              <div className="pure-u-1-3">
              <iframe width="90%" height="315" src="https://www.youtube.com/embed/5KNFmnyWRQk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
           
                </div>
                </div>
               
              </div>
         
            </div>

        </div>

        
            </div>
        )
        
    }
}

export default Speakerseries;