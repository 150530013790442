import React from "react";
import axios from "axios";

import ProjectCard from "../components/ProjectCard";
import {
  Switch,
  Route,
  Link,
} from "react-router-dom";
import { Gradient } from 'react-gradient';
import Project from "./Project";
import ProjectEditor from "./ProjectEditor";
import Spinner from "react-spinkit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class ListProjects extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allProjects: props.projects,
      showProjects: props.projects,
      user:props.user,
      isauth:props.isauth,
      explorationMedicine: false,
      lunarExploration: false,
      spaceWeather: false,
      astroBiology: false,
      planetaryDefense: false,
      missionOperations: false,
      earthScience: false,
      disasterResponse: false,
      planetaryStewardship: false,
      cover: "/images/google1.png",
      showdatasetinproject:false,
    };
    this.selectSubject = this.selectSubject.bind(this);
    this.returnSubjectList = this.returnSubjectList.bind(this);
    this.removeDataset = this.removeDataset.bind(this);
  }

  removeDataset(rmid){
    axios
      .post((process.env.REACT_APP_API_IP || "http://localhost:9000") +"/removeDataset", {
        id: rmid,
      })
      .then((res) => {
        if (res.data) {
          console.log("removed");
          // window.location.href = "http://spaceml.org";
        }
      })
      .catch((err) => console.log(err));
  }

  colorSwitch(subject) {
    switch (subject) {
      case "exploration medicine":
        return "exploration-medicine-color";
      case "space weather":
        return "space-weather-color";
      case "lunar exploration":
        return "lunar-exploration-color";
      case "astrobiology":
        return "astrobiology-color";
      case "planetary defense":
        return "planetary-defense-color";
      case "mission operations":
        return "mission-operations-color";
      case "earth science":
        return "earth-science-color";
      case "disaster response":
        return "disaster-response-color";
      case "planetary stewardship":
        return "planetary-stewardship-color";
    }
  }

  activeSwitch(subject) {
    console.log("active switch");
    switch (subject) {
      case "exploration medicine":
        if (this.state.explorationMedicine === true) {
          return "true";
        } else {
          return "false";
        }
      case "space weather":
        if (this.state.spaceWeather === true) {
          return "true";
        } else {
          return "false";
        }
      case "lunar exploration":
        console.log(this.state.lunarExploration);
        if (this.state.lunarExploration === true) {
          return "lunar-true";
        } else {
          return "false";
        }
      case "astrobiology":
        if (this.state.astroBiology === true) {
          return "astrobiology-true";
        } else {
          return "false";
        }
      case "planetary defense":
        if (this.state.planetaryDefense === true) {
          return "true";
        } else {
          return "false";
        }
      case "mission operations":
        if (this.state.missionOperations === true) {
          return "true";
        } else {
          return "false";
        }
      case "earth science":
        if (this.state.earthScience === true) {
          return "true";
        } else {
          return "false";
        }
      case "disaster response":
        if (this.state.disasterResponse === true) {
          return "true";
        } else {
          return "false";
        }
      case "planetary stewardship":
        if (this.state.planetaryStewardship === true) {
          return "true";
        } else {
          return "false";
        }
    }
  }

  returnSubjectList() {
    var list = [];
    if (this.state.explorationMedicine === true) {
      list.push("exploration medicine");
    }

    if (this.state.spaceWeather === true) {
      list.push("space weather");
    }

    if (this.state.lunarExploration === true) {
      list.push("lunar exploration");
    }

    if (this.state.astroBiology === true) {
      list.push("astrobiology");
    }

    if (this.state.planetaryDefense === true) {
      list.push("planetary defense");
    }

    if (this.state.missionOperations === true) {
      list.push("mission operations");
    }

    if (this.state.earthScience === true) {
      list.push("earth science");
    }

    if (this.state.disasterResponse === true) {
      list.push("disaster response");
    }

    if (this.state.planetaryStewardship === true) {
      list.push("planetary stewardship");
    }

    console.log(list);

    this.props.bysubject(list);
  }

  selectSubject(e, subject) {
    e.preventDefault();

    switch (subject) {
      case "exploration medicine":
        if (this.state.explorationMedicine === false) {
          this.setState(
            {
              explorationMedicine: true,
            },
            function () {
              this.returnSubjectList();
            }
          );
        } else {
          this.setState(
            {
              explorationMedicine: false,
            },
            function () {
              this.returnSubjectList();
            }
          );
        }

        break;
      case "space weather":
        if (this.state.spaceWeather === false) {
          this.setState(
            {
              spaceWeather: true,
            },
            function () {
              this.returnSubjectList();
            }
          );
        } else {
          this.setState(
            {
              spaceWeather: false,
            },
            function () {
              this.returnSubjectList();
            }
          );
        }

        break;
      case "lunar exploration":
        console.log("select lunar");
        if (this.state.lunarExploration === false) {
          this.setState(
            {
              lunarExploration: true,
            },
            function () {
              this.returnSubjectList();
            }
          );
        } else {
          this.setState(
            {
              lunarExploration: false,
            },
            function () {
              this.returnSubjectList();
            }
          );
        }

        break;
      case "astrobiology":
        if (this.state.astroBiology === false) {
          this.setState(
            {
              astroBiology: true,
            },
            function () {
              this.returnSubjectList();
            }
          );
        } else {
          this.setState(
            {
              astroBiology: false,
            },
            function () {
              this.returnSubjectList();
            }
          );
        }

        break;
      case "planetary defense":
        if (this.state.planetaryDefense === false) {
          this.setState(
            {
              planetaryDefense: true,
            },
            function () {
              this.returnSubjectList();
            }
          );
        } else {
          this.setState(
            {
              planetaryDefense: false,
            },
            function () {
              this.returnSubjectList();
            }
          );
        }

        break;
      case "mission operations":
        if (this.state.missionOperations === false) {
          this.setState(
            {
              missionOperations: true,
            },
            function () {
              this.returnSubjectList();
            }
          );
        } else {
          this.setState(
            {
              missionOperations: false,
            },
            function () {
              this.returnSubjectList();
            }
          );
        }

        break;
      case "earth science":
        if (this.state.earthScience === false) {
          this.setState(
            {
              earthScience: true,
            },
            function () {
              this.returnSubjectList();
            }
          );
        } else {
          this.setState(
            {
              earthScience: false,
            },
            function () {
              this.returnSubjectList();
            }
          );
        }

        break;
      case "disaster response":
        if (this.state.disasterResponse === false) {
          this.setState(
            {
              disasterResponse: true,
            },
            function () {
              this.returnSubjectList();
            }
          );
        } else {
          this.setState(
            {
              disasterResponse: false,
            },
            function () {
              this.returnSubjectList();
            }
          );
        }

        break;
      case "planetary stewardship":
        if (this.state.planetaryStewardship === false) {
          this.setState(
            {
              planetaryStewardship: true,
            },
            function () {
              this.returnSubjectList();
            }
          );
        } else {
          this.setState(
            {
              planetaryStewardship: false,
            },
            function () {
              this.returnSubjectList();
            }
          );
        }

        break;
    }

    
  }

  componentDidMount(){
    console.log("project list mounted---");
    console.log(this.props.isauth)
    
  }

  componentDidUpdate(){
    console.log("project list updated")
    console.log(this.props.isauth)
  }

  render() {
    var projectHeaderStyle = { backgroundImage: this.props.randomcover };

    return (
      <div className="project-explorer">
        <Switch>
          <Route path="/repo/project/:projectId/:showdataset?" render={props => <Project {...props} user1={this.props.username}/>} />
       

          <Route path="/repo/projecteditor" component={ProjectEditor} />

          {/* <Route path="/repo/dataset/" component={Dataset} /> */}
             
         

          <Route path="/">
            <div className="project-list-header" style={projectHeaderStyle}>
              <div className="project-list-nav-holder">
                <div className="project-list-nav max-width pure-g">
                  <div className="pure-u-2-5 project-list-intro">
                    <h3>
                      <FontAwesomeIcon icon={["fas", "layer-group"]} /> Explore ML Projects and Datasets
                    </h3>
                    <h1>
                      explore
                      <br />
                      AI & Space Research
                    </h1>
                    {/* <h2>Explore AI projects from FDL, FDL Europe <br/>and DataQuest.</h2> */}
                  </div>
                  <div className="pure-u-3-5 ">
                    <div className="pure-g">
   
                      <div className="pure-u-1-1 project-list-buttons">
                        <small>
                          <FontAwesomeIcon icon={["fas", "filter"]} /> challenge
                          areas:
                        </small>

                        {this.props.allsubjects.map((subject) => {
                          return (
                            <button
                              onClick={(e) => {
                                this.selectSubject(e, subject);
                              }}
                              className={`button-plain subject-button subject-button-${this.colorSwitch(
                                subject
                              )} subject-button-active-${this.activeSwitch(
                                subject
                              )}`}
                            >
                              {subject}
                            </button>
                          );
                        })}
                      </div>
                   
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pure-g max-width project-list">
              {this.props.projectsloaded && this.props.projects ? (
                this.props.projects.map((project) => {
                  return (
                    <Link
                      to={{ pathname: `/repo/project/${project._id}`, 
                      state: {  
                        user:this.state.user,
                        isauth:this.props.isauth
                      }, }}
                      className="pure-u-xl-1-4 pure-u-lg-1-3 pure-u-md-1-2 pure-u-sm-1-1 pure-u-1-1 card-grid-box"
                    >
                      <ProjectCard project={project} />
                    </Link>
                  );
                })
              ) : (
                <div className="project-list-spinner">
                  <Spinner fadeIn="none" name="three-bounce" />
                </div>
              )}
              {this.props.projectsloaded && this.props.datasets ?(
                this.props.datasets.map((dataset) => {
                  return(
                   
                     
                    <Link to={{ pathname: `/repo/project/${dataset.project_id}/true` , 
                    state: {  
                      // user:this.state.user,
                      isauth:this.props.isauth
                    },}}  className="pure-u-xl-1-4 pure-u-lg-1-3 pure-u-md-1-2 pure-u-sm-1-1 pure-u-1-1 card-grid-box">
                  <div className="cut-card-wrap">
             
                <Gradient gradients = {[
                  [dataset.color, 'white'],
                  [dataset.color, dataset.color]
                ]}
                property="background"
                angle="33deg"
		            transitionType="sequential"
                duration="2000"
                className="cut-card"
                >
                  &nbsp;
                    <div className="cat-tag"> 
                    <FontAwesomeIcon icon={["fas", "database"] } size="s"/>&nbsp; dataset
                    </div>
                  <div className="dataset-card-info">
                    <h1>{dataset.title}</h1>
                    <h2>{dataset.subtitle}</h2>
                    {/* {dataset._id} */}
                    {/* <button onClick={() => {this.removeDataset(dataset._id)}}>rm</button> */}
                    <span class="card-stats">
                      <FontAwesomeIcon
                        className="commentcolor"
                        icon={["fas", "compact-disc"]}
                      />{" "}
                      {dataset.size} &nbsp;
                      
                    </span>
                  
                </div>
                </Gradient>
                
              </div>
              
              </Link>
           
                  );
                })
              ):(<p></p>)}
              
              
                
            </div>
          </Route>

          
        </Switch>
      </div>
    );
  }
}

export default ListProjects;
