import React from "react";

import MailchimpSubscribe from "react-mailchimp-subscribe"
import ProjectCard from "../components/ProjectCard";
import GitHubButton from 'react-github-btn'
import ReactGA from "react-ga4";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactTwitterFollowButton from 'react-twitter-follow-button';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
} from "react-router-dom";
import Project from "./Project";
import Spinner from "react-spinkit";
import Medium from "./Medium";

ReactGA.initialize("G-S10ZRX1FCX");
class LandingPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount(){
    // ReactGA.pageview(window.location.pathname + window.location.search);
    ReactGA.event({
      category: 'action',
      action: 'Open Repo'
    });
    ReactGA.send({ hitType: "pageview", page: "/repo" });
  }

  render() {
    return (
      <div className="landing-page-container">
        

        <div className="landing-page-top" >
          
            <div className="landing-page-welcome medium-plus-width pure-g">
            {/* <div className="pure-u-1-4">

            </div> */}
             <div className="pure-u-1-1 landing-logo-holder">
             <img src="./images/logowsticker.png" className="pure-img"/>
             </div>
             {/* <div className="pure-u-1-4">

            </div> */}
            <div className="pure-u-1-4"></div>
              <div className="pure-u-1-2 ">
              
                <h2>SpaceML is a <b>machine learning toolbox</b> and <b>developer community</b> building open science AI applications for space science and exploration.</h2>

       
                  
              </div>
              <div className="pure-u-1-1 landing-page-buttons">
              <br />
              <br />
              
              <GitHubButton href="https://github.com/spaceml-org" data-size="large" aria-label="@spaceml-org on GitHub">@spaceml-org</GitHubButton> 
              &nbsp; &nbsp;
              <ReactTwitterFollowButton twitterAccount="spacemlorg" showLarge={true} showName={true} showCount={false} />

              
              </div>
              {/* <div className="pure-u-1-1">
             
               
                <h2 className="overline"><a href="/repo"><FontAwesomeIcon icon={["fas", "layer-group"]} /> &nbsp;Explore </a> analysis-ready datasets, projects and ML-OPS.</h2>
                <br/>
                <br/>
              </div> */}
              
             
            
            </div>
            
          
        </div>
        {/* <div className="landing-page-middle landing-page-white">
          <div className="landing-page-about max-width pure-g">
            <div className="pure-u-1-1">
              <h1>Featured projects and datasets:</h1>
              <p>Explore the repo of datasets, projects and ML-OPS</p>
              <div className="pure-g">
                <div className="pure-u-1-4">
                {this.props.projectsloaded && this.props.projects ? (
                    <Link
                    to={{ pathname: `/repo/project/${this.props.projects[0]._id}}` }}
                    className="pure-u-xl-1-4 pure-u-lg-1-3 pure-u-md-1-2 pure-u-sm-1-1 pure-u-1-1 card-grid-box-landingpage"
                  >
                    <ProjectCard project={this.props.projects[0]} />
                  </Link>
                  ): (
                    <p></p>
                  )}
                </div>
              </div>
                
            </div>
          </div>
        </div> */}
        <div className="landing-page-middle landing-page-white" >
        <div className="landing-page-about max-width pure-g">
            <div className="pure-u-1-4 ">
                <h1>Recent <br/>SpaceML <br/>Projects</h1>
                <p><a href="/repo">Explore the repo of datasets, projects and ML-OPS</a></p>
               
              </div>
              <div className="pure-u-1-4 project-highlight">
                
     
                {this.props.projectsloaded && this.props.projects ? (
                  <Link
                  to={{ pathname: `/repo/project/${this.props.projects[0]._id}`, state: {  
                   
                    isauth:this.props.isauth
                  }, }}
                  className=" card-grid-box-landingpage" 
                >
                  <ProjectCard project={this.props.projects[0]} />
                </Link>
                ): (
                  <p></p>
                )}

                
                
              </div>
              <div className="pure-u-1-4 project-highlight">
                
     
                {this.props.projectsloaded && this.props.projects ? (
                  <Link
                  to={{ pathname: `/repo/project/${this.props.projects[1]._id}`, state: {  
                   
                    isauth:this.props.isauth
                  }, }}
                  className=" card-grid-box-landingpage"
                >
                  <ProjectCard project={this.props.projects[1]} />
                </Link>
                ): (
                  <p></p>
                )}

                
                
              </div>

              <div className="pure-u-1-4 project-highlight">
                
     
                {this.props.projectsloaded && this.props.projects ? (
                  <Link
                  to={{ pathname: `/repo/project/${this.props.projects[2]._id}`, state: {  
                   
                    isauth:this.props.isauth
                  }, }}
                  className=" card-grid-box-landingpage"
                >
                  <ProjectCard project={this.props.projects[2]} />
                </Link>
                ): (
                  <p></p>
                )}

                
                
              </div>
            
            </div>

        </div>
        <div className="landing-page-middle landing-page-grey">
          <div className="landing-page-about max-width pure-g">
          <div className="pure-u-1-1">
              
              <h1>Latest spaceml news</h1>
              <p>Read the <a href="https://medium.com/spaceml-mentorship-programme-for-cams">Spaceml Publication</a> on Medium</p>
              <br />
              <br />
              <Medium />
            </div>
            <div className="pure-u-1-1">
              <br />
            <h1>spaceml videos</h1>
            <div className="pure-g">
            <div className="pure-u-1-3">
              <iframe width="90%" height="315" src="https://www.youtube.com/embed/rhxyC9MkWGU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
              <div className="pure-u-1-3">
              <iframe width="90%" height="315" src="https://www.youtube.com/embed/vuqRr6DZCMw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
              <div className="pure-u-1-3">
              <iframe width="90%" height="315" src="https://www.youtube.com/embed/5KNFmnyWRQk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
           
                </div>
            </div>
            
            </div>
          </div>
        </div>
        <div className="landing-page-middle landing-page-dark">
          <div className="landing-page-about max-width pure-g">
            <div className="pure-u-1-2 ">
            <h1>SpaceML</h1>
              
                <p>SpaceML helps build the machine learning infrastructure needed to streamline and super-charge the intelligent applications, automation and robotics needed to explore deep space and better manage our planetary spaceship for mutual benefit. 
                <br />
                <br />
                 <a href="https://arxiv.org/abs/2012.10610">Space ML: Distributed Open-source Research with Citizen Scientists for the Advancement of Space Technology for NASA [arXiv.org:2012.10610]</a>

                </p>
            </div>
            <div className="pure-u-1-2">
            <img src="./images/space_diagram.png" className="pure-img"/>
            <br />
                <br />
            <p>As SpaceML continues to grow, it will help bridge the gap between data storage, code sharing and 
server-side (cloud) analysis.</p>
            </div>
           
          </div>
        </div>

     


        <div className="landing-page-middle landing-page-white">
          <div className="landing-page-about max-width pure-g">
            <div className="pure-u-1-1">
              <h1 id="subscribe">SpaceML Newsletter</h1>
              <p>Subscribe to the SpaceML Newsletter:</p>
              <div className="mailchimp">
                <MailchimpSubscribe url="https://imaginals.us8.list-manage.com/subscribe/post?u=aab25205f987cb87f23e7c0ec&id=705458bd1b"/>
              </div>
              <br /><br />
              <p>
              You can unsubscribe at any time by clicking the link in the footer of our emails. 
<br /><br />
We use Mailchimp as our newsletter platform. By clicking above to subscribe, you acknowledge that your information will be transferred to Mailchimp for processing.
              </p>

              <p>
              <br /><br />
              You can also email us on <a href="mailto:jodie@fdl.ai">jodie@fdl.ai</a> and a member of our team will be in touch.
              </p>
             

                
            </div>
          </div>
        </div>

        
        
      </div>
    );
  }
}

export default LandingPage;
