import React from "react";
import ReactGA from "react-ga4";

import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import "./App.css";

import axios from "axios";

import LandingPage from "./components/LandingPage";
import Medium from "./components/Medium";

import ListProjects from "./components/ListProjects";
import ProjectForm from "./components/ProjectForm";
import RegisterUser from "./components/RegisterUser";
import LoginUser from "./components/LoginUser";
import UserProfile from "./components/UserProfile";
import Footer from "./components/Footer";
import TopNav from "./components/TopNav";
import About from "./components/About";

import HelioBrowser from "./components/HelioBrowser";
import Repo from "./components/Repo";

import setAuthToken from "./utils/setAuthToken";
import jwt_decode from "jwt-decode";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "react-modal";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./utils/falibrary";
import Speakerseries from "./components/Speakerseries";

// ReactGA.initialize("UA-175428925-2");
ReactGA.initialize("G-S10ZRX1FCX");
ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });


class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      maincover: "",
      projectsLoaded: false,
      apiResponse: "",
      dbResponse: "",
      projects: [],
      helioprojects:[],
      datasets: "",
      viewAlphaPopup: false,
      newProject: {
        title: "",
        subtitle: "",
        desc: "",
        year: "",
        subject: "",
        color: "",
        summary:
          "<h1>Introduction</h1> <p> Challenge / project introduction <b>(Replace me!)</b> </p> <h1>Results</h1> <p> Showcasing project results <b>(Replace me!)</b> </p> <h1>Method</h1> <p> Project method <b>(Replace me!)</b></p> <h1>Conclusion & Recommendations </h1> <p> Conclusion and recommendations <b>(Replace me!)</b></p>",
        coverimage: "",
        researchers: "",
        mentors: "",
        programme: "",
        institution: "",
        subjectshort: "",
      },
      user: {
        username: "",
        id: "",
        fullname: "",
        gitlink: "",
        roles: [],
      },
      isAuthenticated: false,
      allSubjects: [],
      showRegister: false,
      showProfileDropdown: false,
    };

    this.callAPI = this.callAPI.bind(this);
    this.callDB = this.callDB.bind(this);
    this.addProject = this.addProject.bind(this);
    this.getProjects = this.getProjects.bind(this);
    this.submitNewProject = this.submitNewProject.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.getAllSubjects = this.getAllSubjects.bind(this);
    this.registerUser = this.registerUser.bind(this);
    this.openRegisterModal = this.openRegisterModal.bind(this);
    this.openLoginModal = this.openLoginModal.bind(this);
    this.setCurrentUser = this.setCurrentUser.bind(this);
    this.logoutUser = this.logoutUser.bind(this);
    this.getProjectsBySubject = this.getProjectsBySubject.bind(this);
    this.openProfileDropdown = this.openProfileDropdown.bind(this);
    this.closeProfileDropdown = this.closeProfileDropdown.bind(this);
    this.handleCloseAlphaPopup = this.handleCloseAlphaPopup.bind(this);
    this.randomcover = this.randomcover.bind(this);
    this.getDatasets = this.getDatasets.bind(this);
  }

  randomcover() {
    // var covers = ["linear-gradient(to bottom, rgba(0,0,0,0.8), rgba(0,0,0,0.6)), url('/images/google1-min.png')","linear-gradient(to bottom, rgba(0,0,0,0.3), rgba(0,0,0,0.5)), url('/images/google2-min.png')","linear-gradient(to bottom, rgba(0,0,0,0.8), rgba(0,0,0,0.6)), url('/images/google3-min.png')","linear-gradient(to bottom, rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url('/images/google4-min.png')"];
    var covers = [
      "radial-gradient(circle, rgba(0,0,0,0.9) 0%, rgba(0,0,0,0.6)100%), url('/images/google4-min.png')",
    ];

    var randomcover = covers[Math.floor(Math.random() * covers.length)];
    this.setState({
      maincover: randomcover,
    });
  }

  toasttest = () => {
    toast(<div>hey {this.state.user.username}</div>);
  };

  welcomebacktoast = () => {
    toast(
      <div>
        Welcome back <b>{this.state.user.username}</b>!
      </div>,
      { autoClose: 1500 }
    );
  };

  logoutoast = () => {
    toast(
      <div>
        <b> Logged out</b>!
      </div>,
      { autoClose: 1500 }
    );
  };

  setCurrentUser(user) {
    console.log("current user:");
    console.log(user);

    axios
      .get(
        (process.env.REACT_APP_API_IP || "http://localhost:9000") +
          "/getUser",
        {
          params: {
            id: user.id,
          },
        }
      )
      .then((res) => {
        {
          console.log("got user:");
          console.log(res);
          this.setState({
            isAuthenticated: true,
            user: {
              username: res.data.username,
              fullname: res.data.fullname,
              id: res.data._id,
              gitlink: res.data.gitlink,
              roles: res.data.roles,
            },
          });
        }
      })
      .catch((err) => console.log(err));
  }

  logoutUser() {
    console.log("logout!");
    localStorage.removeItem("jwtToken");
    setAuthToken(false);
    this.setState({
      isAuthenticated: false,
      user: {
        name: "",
      },
    });
    ReactGA.event({
      category: "user",
      action: "log out",
    });
    this.logoutoast();
  }

  callAPI() {
    fetch(
      (process.env.REACT_APP_API_IP || "http://localhost:9000") +
        "/testAPI"
    )
      .then((res) => res.text())
      .then((res) =>
        this.setState({
          apiResponse: res,
        })
      );
  }

  callDB() {
    fetch(
      (process.env.REACT_APP_API_IP || "http://localhost:9000") + "/testDB"
    )
      .then((res) => res.text())
      .then((res) =>
        this.setState({
          dbResponse: res,
        })
      )
      .catch((err) => err);
  }

  registerUser() {
    const user = {
      username: "test",
      email: "email@email.com",
      password: "password",
    };

    axios
      .post(
        (process.env.REACT_APP_API_IP || "http://localhost:9000") +
          "/registerUser",
        user
      )
      .then((res) => {
        if (res.data) {
          console.log("added user");
        } else {
        }
      })
      .catch((err) => console.log("ERROR"));
  }

  addProject() {
    const project = { title: "test1" };

    axios
      .post(
        (process.env.REACT_APP_API_IP || "http://localhost:9000") +
          "/postProject",
        project
      )
      .then((res) => {
        if (res.data) {
          console.log("posted");
        } else {
        }
      });
  }

  getProjects() {
    axios
      .get(
        (process.env.REACT_APP_API_IP || "http://localhost:9000") +
          "/getProjects"
      )
      .then((res) => {
        if (res.data) {
          
          if(res.data.length > 0){
            console.log("got projects!");
          console.log(res.data)
            this.setState({
              projects: res.data,
              projectsLoaded: true,
            });
          }
          
        }
      })
      .catch((err) => console.log(err));
  }

  getDatasets() {
    axios
      .get(
        (process.env.REACT_APP_API_IP || "http://localhost:9000") +
          "/getAllDatasets"
      )
      .then((res) => {
        if (res.data) {
          this.setState({
            datasets: res.data,
          });
        }
      })
      .catch((err) => console.log(err));
  }

  getProjectsBySubject(subject) {
    console.log("getting this list of subjects");
    console.log(subject);
    if (subject.length > 0) {
      axios
        .get(
          (process.env.REACT_APP_API_IP || "http://localhost:9000") +
            "/getProjectsBySubject",
          {
            params: {
              subject: subject,
            },
          }
        )
        .then((res) => {
          if (res.data) {
            console.log(res.data)
            this.setState({
              projects: res.data,
            });

            axios
              .get(
                (process.env.REACT_APP_API_IP || "http://localhost:9000") +
                  "/getDatasetsBySubject",
                {
                  params: {
                    subject: subject,
                  },
                }
              )
              .then((res) => {
                if (res.data) {
                  this.setState({
                    datasets: res.data,
                  });
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      this.getProjects();
    }
  }

  getAllSubjects() {
    // todo: get list of subjects from database

    // axios
    //   .get((process.env.REACT_APP_API_IP || "http://localhost:9000") +"/getAllSubjects")
    //   .then((res) => {
    //     if (res.data) {
    //       console.log("all subs")
    //       console.log(res.data)
    //       this.setState({
    //         allSubjects: res.data,
    //       });
    //     }
    //   })
    //   .catch((err) => console.log(err));

    this.setState({
      allSubjects: [
        "lunar exploration",
        "exploration medicine",
        "space weather",
        "astrobiology",
        "planetary defense",
        "mission operations",
        "disaster response",
        "earth science",
        "planetary stewardship",
      ],
    });
  }

  handleChange(event) {
    console.log("got change event__________");
    console.log(event);

    try {
      event.persist();
    } catch (error) {
      console.log(error);
    }

    this.setState((prevState) => ({
      newProject: {
        ...prevState.newProject,
        [event.target.name]: event.target.value,
      },
    }));

    console.log(this.state.newProject);
  }

  submitNewProject(event) {
    event.preventDefault();
    let projectToPost = this.state.newProject;
    projectToPost.creator_id = this.state.user.id;

    switch (projectToPost.subject) {
      case "exploration medicine":
        projectToPost.color = "#DC3D52";
        projectToPost.subjectshort = "explorationmedicine";
        break;
      case "space weather":
        projectToPost.color = "#EFB93B";
        projectToPost.subjectshort = "spaceweather";
        break;
      case "lunar exploration":
        projectToPost.color = "#41539E";
        projectToPost.subjectshort = "lunar";
        break;
      case "astrobiology":
        projectToPost.color = "#30A0A3";
        projectToPost.subjectshort = "astrobiology";
        break;
      case "planetary defense":
        projectToPost.color = "#941D81";
        projectToPost.subjectshort = "planetarydefense";
        break;
      case "mission operations":
        projectToPost.color = "#6A549D";
        projectToPost.subjectshort = "missionoperations";
        break;
      case "earth science":
        projectToPost.color = "#30AC67";
        projectToPost.subjectshort = "earthscience";
        break;
      case "disaster response":
        projectToPost.color = "#F08B02";
        projectToPost.subjectshort = "disasterresponse";
        break;
      case "planetary stewardship":
        projectToPost.color = "#4A83C4";
        projectToPost.subjectshort = "planetarystewardship";
        break;
    }

    console.log(projectToPost);
    axios
      .post(
        (process.env.REACT_APP_API_IP || "http://localhost:9000") +
          "/postProject",
        projectToPost
      )
      .then((res) => {
        if (res.data) {
          console.log("posted");
          this.getProjects();
          window.location.href = "http://spaceml.org";
        }
      })
      .catch((err) => console.log(err));
  }

  componentDidMount() {
    this.randomcover();
    let visited = localStorage["alreadyVisited"];
    if (visited) {
      this.setState({ viewAlphaPopup: false });
      //do not view Popup
    } else {
      //this is the first time
      localStorage["alreadyVisited"] = true;
      this.setState({ viewAlphaPopup: false });
    }
    // this.callAPI();
 
    const location = "null";
    if(location == "helio"){
      this.getProjectsBySubject(["space weather"])
    } else {
      this.getProjects();
      this.getDatasets();
    }
   
    this.getAllSubjects();
    
    
    //check for a token
    if (localStorage.jwtToken) {
      const token = localStorage.jwtToken;
      setAuthToken(token);
      const decoded = jwt_decode(token);
      this.setCurrentUser(decoded);
      console.log("found JWT token");
    }
  }

  handleCloseAlphaPopup() {
    this.setState({ viewAlphaPopup: false });
  }

  openRegisterModal() {
    console.log("open register");
    this.refs.registermodal.handleOpenModal();
  }

  openLoginModal() {
    console.log("open login modal");
    this.refs.loginmodal.handleOpenModal();
  }

  openProfileDropdown() {
    console.log("open profile dropdown");
    ReactGA.event({
      category: 'action',
      action: 'Open profile dropdown'
    });
    this.setState({
      showProfileDropdown: true,
    });
  }

  closeProfileDropdown() {
    console.log("close profile dropdown");
    this.setState({
      showProfileDropdown: false,
    });
  }

  render() {
    let { projects } = this.state;
    const location = "null"
    if(location == "helio"){
      // this.getProjectsBySubject(["spaceweather"])
      //render helio page
      return (
        <div className="App-container">
          <Router>
            <TopNav logoutUser={this.logoutUser} isHelio={true} isAuth={this.state.isAuthenticated} openRegister={this.openRegisterModal} openLogin = {this.openLoginModal} user={this.state.user}/>
          

          <Switch>
            <Route path="/userprofile/:userId" component={UserProfile} />
            

            <Route path="/">
              <HelioBrowser 
                projects = {this.state.projects}
                datasets={this.state.datasets}
                isauth = {this.state.isAuthenticated}
              />
            </Route>
            
          </Switch>
          </Router>
          <RegisterUser ref="registermodal" next={this.openLoginModal} />
        <LoginUser
          ref="loginmodal"
          welcomeback={this.welcomebacktoast}
          setCurrentUser={this.setCurrentUser}
        />

        <Footer />

        </div>
      )
    } else {
      return (
   
        <div className="App-container">
        <ToastContainer
          position="top-center"
          autoClose={4000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Router>
          <TopNav logoutUser={this.logoutUser} isAuth={this.state.isAuthenticated} openRegister={this.openRegisterModal} openLogin = {this.openLoginModal} user={this.state.user}/>
          

          {/* A <Switch> looks through its children <Route>s and
                renders the first one that matches the current URL. */}
          <Switch>
            <Route path="/userprofile/:userId" component={UserProfile} />
            {/* Protected route to projectform */}
            <Route
              path="/projectform"
              render={() =>
                this.state.isAuthenticated ? (
                  <ProjectForm
                    allsubjects={this.state.allSubjects}
                    subject={this.state.newProject.subject}
                    title={this.state.newProject.title}
                    subtitle={this.state.newProject.subtitle}
                    desc={this.state.newProject.desc}
                    year={this.state.newProject.year}
                    summary={this.state.newProject.summary}
                    coverimage={this.state.newProject.coverimage}
                    institution={this.state.newProject.institution}
                    color={this.state.newProject.color}
                    handleFormSubmit={this.submitNewProject}
                    handleChange={this.handleChange}
                    researchers={this.state.newProject.researchers}
                    mentors={this.state.newProject.mentors}
                  />
                ) : null
              }
            />

            
            <Route path="/speakerseries">
              <Speakerseries />
            </Route>

            <Route path="/repo">
              <Repo
                projects={this.state.projects}
                allsubjects={this.state.allSubjects}
                bysubject={this.getProjectsBySubject}
                projectsloaded={this.state.projectsLoaded}
                randomcover={this.state.maincover}
                datasets={this.state.datasets}
                username={this.state.user.username}
                user={this.state.user}
                isauth={this.state.isAuthenticated}
                openRegister={this.openRegisterModal}
              />
            </Route>

            <Route path="/about">
              <About />
            </Route>
            <Route path="/">
              <LandingPage
                projects={this.state.projects}
                projectsloaded={this.state.projectsLoaded}
                isauth={this.state.isAuthenticated}
              />
            </Route>

            {/* <Route path="/:id" children={<Project />} /> */}
          </Switch>
        </Router>
        <RegisterUser ref="registermodal" next={this.openLoginModal} />
        <LoginUser
          ref="loginmodal"
          welcomeback={this.welcomebacktoast}
          setCurrentUser={this.setCurrentUser}
        />
        <Footer />


      </div>
      

      )}
      
    
    }
    
  }


export default App;
