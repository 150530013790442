import React from "react";

class Footer extends React.Component {
    constructor(props){
        super(props);
        this.state = {};
    }

    render(){
        return(
            <div className="footer">
        
            <p>SpaceML Alpha v1.2.48</p>
            <p>Copyright Trillium Technologies Inc 2016-2023. All rights reserved</p>
            <br />
            <a href="http://spaceml.org">spaceml.org</a>
            <br/>
            <br/>
            <img src="./images/trilliumlogo.png" /> &nbsp; &nbsp;&nbsp;

        </div>
        )
        
    }
}

export default Footer;