import axios from "axios";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../utils/falibrary";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
} from "react-router-dom";

class ProjectCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cover: {},
    };

    this.getCoverImage = this.getCoverImage.bind(this);
  }

  getCoverImage() {
    console.log("getting cover image");
    axios
      .get((process.env.REACT_APP_API_IP || "http://localhost:9000") +"/getImage", {
        params: {
          id: this.props.project.coverimage,
        },
      })
      .then((res) => {
        console.log(res);

        if (res.data) {
          console.log("got image data");
          console.log(res.data.imagedata);
          this.setState({
            cover: res.data.imagedata,
          });
        }
      });
  }

  componentDidMount() {
    this.getCoverImage();
  }

  render() {
    var project = this.props.project;
    var stripeStyle = {
      backgroundColor: project.color,
    };
    var headerStyle = {
      backgroundColor: project.color,
      backgroundImage: 'url("' + this.state.cover + '")',
      backgroundSize: "cover",
      backgroundPosition: "bottom",
    };
    var subjectColor = {
      color: project.color,
    };

    return (
      <div className="card">
        <div className="card-header" style={headerStyle}>
        &nbsp;
        <div className="cat-tag"> 
        
                    <FontAwesomeIcon icon={["fas", "book"] } size="s"/>&nbsp; project
                    </div>
        </div>
        <div className="title-card-stripe" style={stripeStyle}>
          &nbsp;
        </div>
        <div className="card-body">
          <div className="card-body-container">
            <h1>{project.title}</h1>
            {project.subtitle.length > 60 ? (
              <h3>{`${project.subtitle.substring(0, 60)}...`}</h3>
            ) : (
              <h3>{project.subtitle}</h3>
            )}

            {/* <span class="card-stats">
              <FontAwesomeIcon className="redheart" icon={["fas", "heart"]} /> 0
              &nbsp;{" "}
              <FontAwesomeIcon
                className="commentcolor"
                icon={["fas", "comment-dots"]}
              />{" "}
              0
            </span> */}

            <div className="pure-g card-addons">
              <div className="pure-u-1-1">
                <p>challenge area</p>
                <h2 style={subjectColor}>{project.subject}</h2>

                <p>Program</p>
                <h2>
                  {project.programme}
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProjectCard;
