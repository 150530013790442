import React from "react";
import axios from "axios";

import ProjectCard from "../components/ProjectCard";
import { Gradient } from 'react-gradient';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
  } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Project from "./Project";
import Dataset from "./Dataset";
import ProjectEditor from "./ProjectEditor";


class Repo extends React.Component {
    constructor(props){
        super(props)

        this.state = {
            loaded:false,
            filteredProjects:[],
            filteredDatasets:[],
            spaceweatherFilter:false,
            earthscienceFilter:false,
            astrobiologyFilter:false,
            planetardefenseFilter:false,
            active:'',
            showdatasets:false,
            showprojects:false,
            showalltypes:true
        }
        
        this.filterProject = this.filterProject.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.filterChallengearea = this.filterChallengearea.bind(this);
        this.removeDataset = this.removeDataset.bind(this);
    }

    removeDataset(rmid){
        axios
          .post((process.env.REACT_APP_API_IP || "http://localhost:9000") +"/removeDataset", {
            id: rmid,
          })
          .then((res) => {
            if (res.data) {
              console.log("removed");
              // window.location.href = "http://spaceml.org";
            }
          })
          .catch((err) => console.log(err));
      }

   filterProject(searchterm){
       console.log("filter")
       
       const filtered = this.props.projects.filter(
           project => {
               return(
                   project
                   .title
                   .toLowerCase()
                   .includes(searchterm)
               )
           }
       )
       if(this.props.datasets){
        const filtereddatasets = this.props.datasets.filter(
            dataset => {
                return(
                    dataset
                    .title
                    .toLowerCase()
                    .includes(searchterm)
                )
            }
        )

        this.setState(
            {
          
                filteredDatasets:filtereddatasets
            }
        )
       }
       
       this.setState(
           {
               filteredProjects:filtered,
           }
       )
   }
   filterType(e,type){
       if(type === "all"){
           if(this.state.showalltypes == true){
                this.setState({
                showalltypes:false
               });
           } else {
            this.setState({
                showalltypes:true,
                showdatasets:false,
                showprojects:false
               });
           }
       } else if(type==="datasets"){
           
            this.setState({
                showalltypes:false
            })
            if(this.state.showdatasets == true){
                this.setState({
                    showdatasets:false
                })
            }else{
                this.setState({
                    showdatasets:true,
                    showprojects:false,
                    showalltypes:false                })
            }
           
       } else if(type==="projects"){
           
        this.setState({
            showalltypes:false
        })
        if(this.state.showprojects == true){
            this.setState({
                showprojects:false
            })
        }else{
            this.setState({
                showprojects:true,
                showdatasets:false,
                showalltypes:false
            })
        }
    }
   }
   filterChallengearea(e, challengearea){
       

        if(this.state.active === challengearea.replace(/\s/g, "")){
            this.setState({
                filteredProjects:this.props.projects,
                filteredDatasets:this.props.datsets,
                active:''
            })
        } else {

            const filtered = this.props.projects.filter(
                project => {
                    return(
                        project
                        .subject
                        .toLowerCase()
                        .includes(challengearea)
                        )
                }
            )
            this.setState({
                filteredProjects:filtered,
                active:challengearea.replace(/\s/g, "")
            })

            if(this.props.datasets){
                const filtereddatasets = this.props.datasets.filter(
                    dataset => {
                        return(
                            dataset
                            .subject
                            .toLowerCase()
                            .includes(challengearea)
                        )
                    }
                )
        
                this.setState(
                    {
                  
                        filteredDatasets:filtereddatasets
                    }
                )
               }

        }
       
   }

   handleSearch(e){
        this.filterProject(e.target.value);
   }

    componentDidMount(){
        this.filterProject("")
    }

    componentDidUpdate(){
        if(this.props.projectsloaded && !this.state.loaded){
            this.setState({loaded:true})
            this.filterProject("")
            
        }
    }

    render() {
        var projectHeaderStyle = { backgroundImage: "radial-gradient(circle, rgba(0,0,0,0.9) 0%, rgba(0,0,0,0.6)100%), url('/images/google4-min.png')" };
        return (
            <div className = "project-explorer">
                <Switch>
                <Route path="/repo/project/:projectId/:showdataset?" render={props => <Project {...props} user1={this.props.username} onClick={this.props.openRegister} isauth={this.props.isauth}/>} />
       

       <Route path="/repo/projecteditor" component={ProjectEditor} />
                    <Route path="/">
                        <div className="project-list-header" style={projectHeaderStyle}>
                            <div className="project-list-nav-holder">
                                <div className="project-list-nav max-width pure-g">
                                    <div className="pure-u-2-5 project-list-intro">
                                        <h3>
                                            <FontAwesomeIcon icon={["fas", "layer-group"]} /> Explore Open ML Projects and Datasets
                                        </h3>
                                        <h1>
                                        explore
                                        <br />
                                        AI & Space Research
                                     
                                        </h1>
                                    </div>

                                    <div className="pure-u-3-5">
                                        <div className="pure-g">
                                            <div className="pure-u-1-1  project-list-buttons">
                                            <small>
                                                 search
                                                 <br />
                                                </small>
                                                <input 
                                                    className="searchbar"
                                                    type = "search" 
                                                    placeholder = "Search for projects and datasets.." 
                                                    onChange = {this.handleSearch}
                                                    />
                                            </div>
                                            <div className="pure-u-1-1 project-list-buttons">
                                                <small>
                                                 challenge areas
                                                 <br />
                                                </small>
                                                <button
                                                    onClick={(e) => {
                                                        this.filterChallengearea(e, "")
                                                    }}
                                                    className={this.state.active === '' ? 
                                                    'subject-button-all-active challenge-filter-button' : 
                                                    'challenge-filter-button subject-button-all'}
                                                    >
                                                    All Challenge Areas
                                                </button>
                                                <button
                                                    onClick={(e) => {
                                                        this.filterChallengearea(e, "space weather")
                                                    }}
                                                    className=
                                                    {this.state.active === 'spaceweather' ? 
                                                    'spaceweather-active challenge-filter-button subject-button-space-weather-color' : 
                                                    'challenge-filter-button subject-button-space-weather-color'}
                                                    >
                                                    Space Weather
                                                </button>
                                                <button
                                                    onClick={(e) => {
                                                        this.filterChallengearea(e, "earth science")
                                                    }}
                                                    className={this.state.active === 'earthscience' ? 
                                                    'earthscience-active challenge-filter-button subject-button-earth-science-color' : 
                                                    'challenge-filter-button subject-button-earth-science-color'}
                                                    >
                                                    Earth Science
                                                </button>
                                                <button
                                                    onClick={(e) => {
                                                        this.filterChallengearea(e, "planetary defense")
                                                    }}
                                                    className={this.state.active === 'planetarydefense' ? 
                                                    'planetary-defense-active challenge-filter-button subject-button-planetary-defense-color' : 
                                                    'challenge-filter-button subject-button-planetary-defense-color'}
                                                    >
                                                    Planetary Defense
                                                </button>
                                                <button
                                                    onClick={(e) => {
                                                        this.filterChallengearea(e, "astrobiology")
                                                    }}
                                                    className={this.state.active === 'astrobiology' ? 
                                                    'astrobiology-active challenge-filter-button subject-button-astrobiology-color' : 
                                                    'challenge-filter-button subject-button-astrobiology-color'}
                                                    >
                                                    Astrobiology
                                                </button>
                                                <button
                                                    onClick={(e) => {
                                                        this.filterChallengearea(e, "disaster response")
                                                    }}
                                                    className={this.state.active === 'disasterresponse' ? 
                                                    'disasterresponse-active challenge-filter-button subject-button-disasterresponse-color' : 
                                                    'challenge-filter-button subject-button-disasterresponse-color'}
                                                    >
                                                    Disaster Response
                                                </button>
                                            </div>
                                            <div className="pure-u-1-1 project-list-buttons">
                                                <small>
                                                 Type
                                                 <br />
                                                </small>
                                                <button
                                                    onClick={(e) => {
                                                        this.filterType(e,"all")
                                                    }}
                                                    className={this.state.showalltypes ? 
                                                        'challenge-filter-button challenge-filter-button-active' : 
                                                        'challenge-filter-button'}
                                                    >
                                                     All Types 
                                                </button>
                                                <button
                                                    onClick={(e) => {
                                                        this.filterType(e,"projects")
                                                    }}
                                                    className={this.state.showprojects ? 
                                                        'challenge-filter-button challenge-filter-button-active' : 
                                                        'challenge-filter-button'}
                                                    >
                                                    <FontAwesomeIcon icon={["fas", "book"] } size="s"/>&nbsp; Projects 
                                                </button>
                                                <button
                                                    onClick={(e) => {
                                                        this.filterType(e,"datasets")
                                                    }}
                                                    className={this.state.showdatasets ? 
                                                    'challenge-filter-button challenge-filter-button-active' : 
                                                    'challenge-filter-button'}
                                                    >
                                                   <FontAwesomeIcon icon={["fas", "database"] } size="s"/>&nbsp; Datasets
                                                </button>
                                                {/* <button
                                                    onClick={(e) => {}}
                                                    className={`challenge-filter-button `}
                                                    >
                                                   <FontAwesomeIcon icon={["fas", "cube"] } size="s"/>&nbsp; Models
                                                </button>
                                                <button
                                                    onClick={(e) => {}}
                                                    className={`challenge-filter-button `}
                                                    >
                                                   <FontAwesomeIcon icon={["fas", "toolbox"] } size="s"/>&nbsp; Tools & Components
                                                </button> */}
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="pure-g max-width project-list">
                            
                            {(this.state.showprojects == true) || (this.state.showalltypes == true) ?
                            <>
                                {this.state.filteredProjects.map((project) => {
                                    return (
                                        <Link to={{ pathname: `/repo/project/${project._id}`, 
                                        state: {  
                                        user:this.state.user,
                                        isauth:this.props.isauth
                                        }, }}
                                        className="pure-u-xl-1-4 pure-u-lg-1-3 pure-u-md-1-2 pure-u-sm-1-1 pure-u-1-1 card-grid-box"
                                        >
                                            <ProjectCard  project={project}/>
                                        </Link>
                                    )
                                })}
                                </>
                            :
                            null
                            }

                            {(this.state.showdatasets == true) || (this.state.showalltypes == true) ?
                            <>
                            {this.state.filteredDatasets.map((dataset) => {
                                return (
                                    <Link to={{ pathname: `/repo/project/${dataset.project_id}/true` , 
                                    state: {  
                                      // user:this.state.user,
                                      isauth:this.props.isauth
                                    },}}  className="pure-u-xl-1-4 pure-u-lg-1-3 pure-u-md-1-2 pure-u-sm-1-1 pure-u-1-1 card-grid-box">
                                  <div className="cut-card-wrap">
                             
                                <Gradient gradients = {[
                                  [dataset.color, 'white'],
                                  [dataset.color, dataset.color]
                                ]}
                                property="background"
                                angle="33deg"
                                    transitionType="sequential"
                                duration="2000"
                                className="cut-card"
                                >
                                  &nbsp;
                                    <div className="cat-tag"> 
                                    <FontAwesomeIcon icon={["fas", "database"] } size="s"/>&nbsp; dataset
                                    </div>
                                  <div className="dataset-card-info">
                                    <h1>{dataset.title}</h1>
                                    <h2>{dataset.subtitle}</h2>
                                    {/* {dataset._id} */}
                                    {/* <button onClick={() => {this.removeDataset(dataset._id)}}>rm</button> */}
                                    <span class="card-stats">
                                      <FontAwesomeIcon
                                        className="commentcolor"
                                        icon={["fas", "compact-disc"]}
                                      />{" "}
                                      {dataset.size} &nbsp;
                                      
                                    </span>
                                  
                                </div>
                                </Gradient>
                                
                              </div>
                              
                              </Link>
                                )
                            })}
                            </>
                            :
                            null
                        }
                        </div>

                    </Route>
                </Switch>
            </div>
        )
    }
}

export default Repo;