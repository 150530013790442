import React from 'react'
import Axios from 'axios'
import MediumItem from './MediumItem';
const { parse } = require('rss-to-json');

class Medium extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            posts: []
        }
    }

    // fetchPosts = () => parse('https://medium.com/feed/spaceml-mentorship-programme-for-cams.xml')

     fetchPosts = () => Axios.get(`https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fmedium.com%2Ffeed%2Fspaceml-mentorship-programme-for-cams%2Ftagged%2Fmachinelearning&api_key=cddzeqbgkxvpanlogfvkdaviokabb9d28txtrgtz`)

    setPosts =({data})  => {
        console.log("feed:")
        console.log(data)
        this.setState({
          posts: Object.values(data.items).slice(0, 3)
        })
      }

    

    componentDidMount(){
        this.fetchPosts().then(this.setPosts);
    }

    componentDidUpdate(){

    }

    render(){
        return (
            <div className="pure-g"> 


        {this.state.posts.map((article,i) => (
           
               
            <MediumItem key={i} {...article} />
           
         
        ))}
    
    </div>
        )
    }
}

export default Medium;